import { translate } from "@ollie-sports/i18n";
import toast from "react-hot-toast";

export function openErrorToast(msg: string) {
  toast.error(msg, { duration: 5000 });
}

export function openSuccessToast(msg?: string) {
  toast.success(msg ?? translate.common.Success, { duration: 5000 });
}

export function openWarningToast(msg?: string) {
  toast.success(msg ?? translate.common.Success, { className: "bg-yellow-500", duration: 5000 });
}
