export function addHeightAndWidthToURI(p: { uri: string; height: number; width: number }) {
  const url = new URL(p.uri);
  url.searchParams.set("height", String(p.height));
  url.searchParams.set("width", String(p.width));
  const cleanUrl = url.toString();
  return cleanUrl;
}

export function getImageDimensionsFromURI(p: { uri: string }) {
  let uri = p.uri;
  if (!uri.match(/^((https?)|(file)):\/\//)) {
    uri = "file://" + uri;
  }
  const url = new URL(uri);
  return {
    height: Number(url.searchParams.get("height")) ?? 0,
    width: Number(url.searchParams.get("width")) ?? 0
  };
}

export function getImageDimensionsForDisplay(
  p: { maxWidthOrHeight: number } & ({ uri: string } | { width: number; height: number })
) {
  const dimensions = "uri" in p ? getImageDimensionsFromURI({ uri: p.uri }) : { width: p.width, height: p.height };

  if (dimensions.width !== 0 && dimensions.height !== 0) {
    return getScaledDimensions({ maxWidthOrHeight: p.maxWidthOrHeight, ...dimensions });
  }

  return undefined;
}

export function getScaledDimensions(p: { maxWidthOrHeight: number; height: number; width: number }) {
  const imageWidthToHeightRatio = p.width / p.height;
  const width = imageWidthToHeightRatio >= 1 ? p.maxWidthOrHeight : p.maxWidthOrHeight * imageWidthToHeightRatio;
  const height = imageWidthToHeightRatio <= 1 ? p.maxWidthOrHeight : p.maxWidthOrHeight / imageWidthToHeightRatio;
  return { width, height };
}

// i18n certified - complete
