import { produce } from "immer";
import zustand, { State, Subscribe, UseStore } from "zustand";

//Don't love the Zustand top level API. This tightens it up a bit IMO
export type ZustandStore<T extends State> = {
  set: (newState: T) => void;
  get: () => T;
  subscribe: Subscribe<T>;
  useStore: UseStore<T>;
  modifyImmutably(modifyFn: (currState: T) => void): void;
  modifyImmutably(modifyFn: (currState: T) => Promise<void>): Promise<void>;
};

export function createZustandStore<StoreState extends State>(initState: StoreState): ZustandStore<StoreState> {
  const useStore = zustand(() => initState);

  return {
    useStore,
    get: () => useStore.getState(),
    set: a => useStore.setState(a, true),
    subscribe: (a: any) => useStore.subscribe(a),
    modifyImmutably: modifyFn => {
      const nextStateRaw = produce(useStore.getState(), modifyFn);

      const nextState: Promise<StoreState> | StoreState = nextStateRaw as any;

      if (nextState instanceof Promise) {
        return nextState.then(newVal => {
          useStore.setState(newVal);
        }) as any;
      } else {
        useStore.setState(nextState);
      }
    }
  };
}
