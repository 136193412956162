import { del, keys, clear, set, get, entries, delMany } from "idb-keyval";
import Emittery from "emittery";
import { useEffect, useState } from "react";

type AsyncStorageWrapper<RecordType> = {
  getItem: (key: string) => Promise<RecordType | null>;
  setItem: (key: string, value: RecordType) => Promise<void>;
  removeItem: (key: string) => Promise<void>;
  clear: () => Promise<void>;
  useItem: (key: string) => RecordType | null;
};

const DB_NAME = "async-storage-wrapper";
export function createAsyncStorageWrapper<RecordType extends Record<string, any>>(p: {
  namespace: string;
}): AsyncStorageWrapper<RecordType> {
  const ee = new Emittery();

  const clearSymbol = Symbol("clear");
  const getKey = (rawKey: string) => [p.namespace, rawKey].join("-");

  return {
    getItem: (rawKey: string) => {
      return get(getKey(rawKey)) as Promise<RecordType | null>;
    },
    setItem: async (rawKey: string, value: RecordType) => {
      await set(getKey(rawKey), value);
      ee.emit(getKey(rawKey), value);
    },
    removeItem: async (rawKey: string) => {
      await del(getKey(rawKey));
      ee.emit(getKey(rawKey), null);
    },
    clear: async () => {
      const theseKeys = await keys().then(a => a.filter(b => b.toString().startsWith(p.namespace)));
      await delMany(theseKeys);
      ee.emit(clearSymbol);
    },
    useItem: (rawKey: string) => {
      const key = getKey(rawKey);
      const [item, setItemState] = useState<null | RecordType>(null);

      useEffect(() => {
        let isMounted = true;
        const unsub = ee.on(key, (newVal: any) => {
          isMounted && setItemState(newVal);
        });

        const unsub2 = ee.on(clearSymbol, () => {
          isMounted && setItemState(null);
        });

        get(key).then(thisItem => {
          isMounted && setItemState(thisItem);
        });

        return () => {
          unsub();
          unsub2();
          isMounted = false;
        };
      }, [key]);

      return item;
    }
  };
}
