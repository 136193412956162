import { translate } from "@ollie-sports/i18n";
import {
  GENDERS,
  OpenOrgEvent,
  OpenOrgEventGroup,
  OpenOrgEventId,
  OpenOrgEventRegistration,
  OpenOrgEventSession,
  OrgId,
  OrgTeamTag,
  TeamId,
  TryoutRegistrationAction,
  TryoutRegistrationActionType
} from "@ollie-sports/models";
import _ from "lodash";
import { NONE } from "../constants";
import { getServerHelpers, getUniversalHelpers } from "../helpers";
import { ObjectKeys } from "./object-keys";
import { SimpleQuery } from "@ollie-sports/firebase-lift";

export function getAllBirthYearAndGenderCombinations(session: Partial<OpenOrgEventSession>) {
  return (session.birthYears ?? []).reduce(
    (acc, birthYear) => {
      (session.genders ?? []).forEach(gender => {
        acc.push({
          birthYear,
          gender
        });
      });
      return acc;
    },
    [] as {
      birthYear: string;
      gender: GENDERS;
    }[]
  );
}

export function getRegistrationStatus(actions: TryoutRegistrationAction[], teamId: TeamId): RegistrationStatus {
  const groupedActions = _.groupBy(
    actions.filter(a => a.teamId === teamId),
    a => a.type
  ) as {
    [key in TryoutRegistrationActionType]?: TryoutRegistrationAction[];
  };

  if (groupedActions["player-accept"]) {
    return "invite-accepted";
  }

  if (groupedActions["manual-player-accept"]) {
    return "manual-player-accept";
  }

  if (groupedActions["player-reject"]) {
    const latestReject = Math.max(...groupedActions["player-reject"].map(a => a.createdAtMS));
    if (groupedActions.invite?.some(a => a.createdAtMS > latestReject)) {
      //Ignore. Superseded by more recent invite
    } else {
      return "invite-rejected";
    }
  }

  if (groupedActions.invite) {
    const latestInviteCancel = Math.max(...(groupedActions["cancel-invite"] ?? []).map(a => a.createdAtMS));
    if (groupedActions.invite.some(a => !a.expiresAtMS || Date.now() < a.expiresAtMS)) {
      if (!groupedActions.invite.some(a => a.createdAtMS > latestInviteCancel)) {
        return "invite-canceled";
      }
      return "invite-pending";
    } else {
      return "invite-expired";
    }
  }

  if (groupedActions.alternateNotificationSent) {
    return "alternate";
  }

  if (groupedActions["rejected-by-coach"]) {
    return "rejected-by-coach";
  }

  if (groupedActions.favorite) {
    return "favorited";
  }

  return "none";
}

export type RegistrationStatus =
  | "none"
  | "favorited"
  | "invite-pending"
  | "invite-rejected"
  | "alternate"
  | "invite-accepted"
  | "invite-expired"
  | "invite-canceled"
  | "manual-player-accept"
  | "rejected-by-coach";

export function getPrettyRegistrationStatus(status: RegistrationStatus, locale: string) {
  switch (status) {
    case "alternate":
      return translate.common(locale).Alternate;
    case "invite-accepted":
      return translate.common(locale).Accepted;
    case "invite-pending":
      return translate({
        defaultMessage: "Pending",
        description: "Player has received an invite but not yet accepted.",
        serverLocale: locale
      });
    case "invite-rejected":
      return translate.common(locale).Declined;
    case "invite-expired":
      return translate.common(locale).Expired;
    case "rejected-by-coach":
      return translate({ defaultMessage: "Rejected", serverLocale: locale });
    default:
      return translate.common(locale).None;
  }
}

export interface OpenOrgEventGetRegistrationProps {
  orgId: OrgId;
  type: "tryout" | "camp";
  selfAccountId: string;
  birthYears?: Record<string, true>;
  genders?: Record<string, true>;
  searchTerm?: string;
  offset?: number;
  limit?: number;
  openOrgEventIds?: Record<string, true>; // This is the filters
  orgTeamTagIds?: Record<string, true>;
  allValidOpenOrgEventIds: Record<string, true>;
}

export async function getOpenOrgEventRegistrations(p: OpenOrgEventGetRegistrationProps) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  let orgTeamTagIds = p.orgTeamTagIds ?? {};

  const validOpenOrgEventIdsInFilters = ObjectKeys(p.openOrgEventIds ?? {}).filter(
    eventId => !!p.allValidOpenOrgEventIds[eventId]
  );

  const openOrgEventIdsToUse = validOpenOrgEventIdsInFilters.length
    ? validOpenOrgEventIdsInFilters.reduce((acc, eventId) => {
        acc[eventId] = true;
        return acc;
      }, {} as Record<string, true>)
    : p.allValidOpenOrgEventIds;

  const baseParam = {
    orgId: { valid: true, val: p.orgId },
    type: { valid: true, val: p.type },
    offset: { valid: true, val: p.offset || 0 },
    birthYears: { valid: Object.keys(p.birthYears || {}).length, val: Object.keys(p.birthYears || {}) },
    genders: { valid: Object.keys(p.genders || {}).length, val: Object.keys(p.genders || {}) },
    orgTeamTagIds: { valid: Object.keys(orgTeamTagIds || {}).length, val: Object.keys(orgTeamTagIds || {}) },
    searchTerm: { valid: !!p.searchTerm, val: `%${p.searchTerm?.replace(/ /g, "").toLowerCase()}%` },
    openOrgEventIds: { valid: Object.keys(openOrgEventIdsToUse || {}).length, val: Object.keys(openOrgEventIdsToUse || {}) }
  };

  const queryParams = _(baseParam)
    .entries()
    .filter(a => !!a[1].valid)
    .map((a, i) => {
      return [a[0], { sqlParam: `$${i + 1}`, value: a[1].val }];
    })
    .fromPairs()
    .value() as { [k in keyof typeof baseParam]?: { sqlParam: string; value: any } };

  const query = `select r.item as registration, count(*) OVER() AS count
  from mirror_openorgeventregistration r,
  mirror_openorgevent o
  where r.item ->> 'openOrgEventId' = o.id and
   r.item ->> 'orgId' = ${queryParams.orgId!.sqlParam}
  and r.item ->> 'type' = ${queryParams.type!.sqlParam}
  ${queryParams.openOrgEventIds ? `and r.item ->> 'openOrgEventId' = any (${queryParams.openOrgEventIds.sqlParam}::text[])` : ""}
    ${
      queryParams.birthYears
        ? `and r.item -> 'tryoutInfo' -> 'sessionSelection' ->> 'birthYear' = any (${queryParams.birthYears.sqlParam}::text[])`
        : ""
    }
    ${
      queryParams.genders
        ? `and r.item -> 'tryoutInfo' -> 'sessionSelection' ->> 'gender' = any (${queryParams.genders.sqlParam}::text[])`
        : ""
    }
    ${
      queryParams.orgTeamTagIds
        ? `and (r.item -> 'tryoutInfo' -> 'sessionSelection' ->> 'orgTeamTagId' = any (${
            queryParams.orgTeamTagIds.sqlParam
          }::text[])${
            queryParams.orgTeamTagIds.value.includes(NONE)
              ? ` or r.item -> 'tryoutInfo' -> 'sessionSelection' -> 'orgTeamTagIds' is null`
              : ""
          })`
        : ""
    }
    ${
      queryParams.searchTerm
        ? `and trim(concat(r.item -> 'playerInfo' ->> 'firstName',r.item -> 'playerInfo' ->> 'lastName', r.item -> 'playerInfo' ->> 'temporaryIdentifier')) ilike ${queryParams.searchTerm.sqlParam}`
        : ""
    }
    ORDER  BY r.item -> 'playerInfo' ->> 'firstName', r.item -> 'playerInfo' ->> 'lastName'
    OFFSET ${queryParams.offset!.sqlParam}
    LIMIT  ${p.limit ?? 300};`;

  const r1 = await getServerHelpers()
    .getAppPgPool()
    .query(
      query,
      Object.values(queryParams).map(a => a.value)
    );

  if (!r1.rowCount) {
    return {
      registrations: [] as OpenOrgEventRegistration[],
      count: 0
    };
  } else {
    return {
      registrations: r1.rows.map(r => {
        return r.registration as OpenOrgEventRegistration;
      }),
      count: (r1.rows[0].count ?? 0) as number
    };
  }
}

export function getEndOfFinalOpenOrgEventSession(sessions: OpenOrgEventSession[]) {
  return sessions.reduce((acc, session) => {
    session.sessionTimes.forEach(sessionTime => {
      const thisSessionTime = `${sessionTime.date}T${sessionTime.endTime}`;
      if (thisSessionTime > acc) {
        acc = thisSessionTime;
      }
    });
    return acc;
  }, "0000-00-00T00:00");
}
