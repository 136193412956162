import { translate } from "@ollie-sports/i18n";
import { PlayerBundle__AccountType } from "@ollie-sports/models";

export function getBodyAndTitleForProfileRequest(p: {
  requestorName: string;
  accountType: PlayerBundle__AccountType;
  playerBundleName: string;
  locale: string;
}) {
  let body = "";

  if (p.accountType === PlayerBundle__AccountType.selfAthlete) {
    if (p.playerBundleName === p.requestorName) {
      body = translate(
        {
          defaultMessage:
            "{requestorName} has requested athlete access to your linked player profile with the same name. Would you like to accept their request?",
          serverLocale: p.locale
        },
        { requestorName: p.requestorName }
      );
    } else {
      body = translate(
        {
          defaultMessage:
            "{requestorName} has requested athlete access to your linked player profile for {playerBundleName}. Would you like to accept their request?",
          serverLocale: p.locale
        },
        { requestorName: p.requestorName, playerBundleName: p.playerBundleName }
      );
    }
  } else {
    if (p.playerBundleName === p.requestorName) {
      body = translate(
        {
          defaultMessage:
            "{requestorName} has requested guardian access to your linked player profile with the same name. Would you like to accept their request?",
          serverLocale: p.locale
        },
        { requestorName: p.requestorName }
      );
    } else {
      body = translate(
        {
          defaultMessage:
            "{requestorName} has requested guardian access to your linked player profile for {playerBundleName}. Would you like to accept their request?",
          serverLocale: p.locale
        },
        { requestorName: p.requestorName, playerBundleName: p.playerBundleName }
      );
    }
  }

  const title = translate({ defaultMessage: "Profile Link Requested", serverLocale: p.locale });

  return { body, title };
}
