import { useOrgSeasons } from "../hooks/useOrgSeasons";
import { createPersistedSynchronousStore } from "./createPersistedSynchronousStore";

const store = createPersistedSynchronousStore<Record<string, string>>({
  initialState: {},
  namespace: "org-current-season-id-asdf",
  shouldBeClearedOnUpdates: false
});

export function useOrgCurrentSeasonId(orgId: string) {
  const savedVal = store.useStore(a => a[orgId] || "");

  const orgSeasons = useOrgSeasons({ orgId, includeArchived: true });

  if (savedVal && orgSeasons && !orgSeasons.find(a => a.id === savedVal)) {
    store.setStore({
      ...store.getStore(),
      [orgId]: ""
    });
  }

  return [
    orgSeasons ? savedVal : "",
    (newSeasonId: string) => {
      store.setStore({
        ...store.getStore(),
        [orgId]: newSeasonId
      });
    }
  ] as const;
}
