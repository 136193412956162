import { translate } from "@ollie-sports/i18n";
import {
  CALENDAR_ENTRY_TYPES,
  GAME_VENUES,
  CalendarEntry,
  CalendarEntryOther,
  CalendarEntryPractice,
  CalendarEntryGameScrimmage,
  Team,
  MATCH_TYPES,
  GENDERS,
  Org
} from "@ollie-sports/models";
import axios from "axios";
import moment, { duration } from "moment";
import { MOMENT_DATE_TIME_FORMAT } from "../constants/Time";

export function getInitialCalendarEntryValues(p: {
  type: CALENDAR_ENTRY_TYPES;
  defaultTimeZone: string;
  team?: Team;
  org?: Org;
}): CalendarEntry {
  switch (p.type) {
    case CALENDAR_ENTRY_TYPES.game:
    case CALENDAR_ENTRY_TYPES.scrimmage:
      return createGameScrimmageInitialValue({ type: p.type, defaultTimeZone: p.defaultTimeZone, team: p.team });
    case CALENDAR_ENTRY_TYPES.practice:
      return createPracticeInitialValue({ defaultTimeZone: p.defaultTimeZone, team: p.team });
    case CALENDAR_ENTRY_TYPES.other:
      return createOtherInitialValue({ defaultTimeZone: p.defaultTimeZone, team: p.team, org: p.org });
  }
}

function rootCreateInitialValue(p: { type: CALENDAR_ENTRY_TYPES; defaultTimeZone: string; team?: Team; org?: Org }) {
  const nearestMin = Math.round(moment().minutes() / 5) * 5;
  const now = moment().minute(nearestMin).second(0).millisecond(0).valueOf();

  let durationMinutes: number;
  if (p.type === CALENDAR_ENTRY_TYPES.other) {
    durationMinutes = 60;
  } else if (p.type === CALENDAR_ENTRY_TYPES.practice) {
    durationMinutes = 90;
  } else {
    durationMinutes = 90; //TODO: Make this look at the team age, etc
  }

  const arriveEarlyMinutes = [CALENDAR_ENTRY_TYPES.game, CALENDAR_ENTRY_TYPES.scrimmage].includes(p.type) ? 45 : 5;

  return {
    teamId: p.team?.id ?? "",
    orgEventOrgId: p.org?.id ?? "",
    id: "",
    teamIdWithSquad: "",
    createdAtMS: Date.now(),
    startDateTime: moment(now).format(MOMENT_DATE_TIME_FORMAT),
    endDateTime: moment(now).add(durationMinutes, "minutes").format(MOMENT_DATE_TIME_FORMAT),
    arrivalDateTime: moment(now).subtract(arriveEarlyMinutes, "minutes").format(MOMENT_DATE_TIME_FORMAT),
    location: {},
    eventNotes: "",
    locationDetails: "",
    timezone: p.defaultTimeZone
  };
}

function createGameScrimmageInitialValue(p: {
  type: CALENDAR_ENTRY_TYPES.game | CALENDAR_ENTRY_TYPES.scrimmage;
  defaultTimeZone: string;
  team?: Team;
}): CalendarEntryGameScrimmage {
  return {
    calendarEntryType: p.type,
    opponentName: "",
    gameVenue: GAME_VENUES.home,
    jerseyColor: p.team?.defaultJerseyColors?.home ?? "",
    ...rootCreateInitialValue({ type: p.type, defaultTimeZone: p.defaultTimeZone, team: p.team })
  };
}

function createPracticeInitialValue(p: { defaultTimeZone: string; team?: Team }): CalendarEntryPractice {
  return {
    calendarEntryType: CALENDAR_ENTRY_TYPES.practice,
    ...rootCreateInitialValue({ type: CALENDAR_ENTRY_TYPES.practice, defaultTimeZone: p.defaultTimeZone, team: p.team })
  };
}

function createOtherInitialValue(p: { defaultTimeZone: string; team?: Team; org?: Org }): CalendarEntryOther {
  return {
    calendarEntryType: CALENDAR_ENTRY_TYPES.other,
    title: "",
    ...rootCreateInitialValue({ type: CALENDAR_ENTRY_TYPES.other, defaultTimeZone: p.defaultTimeZone, team: p.team, org: p.org })
  };
}

export async function getTimezoneFromZip(zip: string, mapsApiKey: string) {
  const res = await axios.get(
    getEdgeCachedURL(
      `https://maps.googleapis.com/maps/api/geocode/json?address=components=postal_code:${zip}&sensor=false&key=${mapsApiKey}`,
      180
    ),
    {
      validateStatus: () => true
    }
  );

  const location = res?.data?.results?.[0]?.geometry?.location;
  if (location) {
    const { lat, lng } = location;
    return await getTimezoneFromLocation({ lat, long: lng }, mapsApiKey);
  }

  return null;
}

export async function getTimezoneFromLocation(coords: { lat: number; long: number }, mapsApiKey: string) {
  const { lat, long } = coords;

  if (!lat || !long) {
    return null;
  }

  const res = await axios.get(
    getEdgeCachedURL(
      `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${long}&timestamp=0&key=${mapsApiKey}`,
      180
    ),
    { validateStatus: () => true }
  );

  if (!res.data.timeZoneId) {
    return null;
  }

  return res.data.timeZoneId;
}

export function isGameScrimmage(calEntry: { calendarEntryType: CALENDAR_ENTRY_TYPES }): calEntry is CalendarEntryGameScrimmage {
  return (
    calEntry.calendarEntryType === CALENDAR_ENTRY_TYPES.game || calEntry.calendarEntryType === CALENDAR_ENTRY_TYPES.scrimmage
  );
}

export function getPrettyEventTypeTitle(eventType: CALENDAR_ENTRY_TYPES | MATCH_TYPES, locale: string) {
  switch (eventType) {
    case CALENDAR_ENTRY_TYPES.game:
    case MATCH_TYPES.game:
      return translate.common(locale).Game;
    case CALENDAR_ENTRY_TYPES.practice:
      return translate.common(locale).Practice;
    case CALENDAR_ENTRY_TYPES.scrimmage:
    case MATCH_TYPES.scrimmage:
      return translate.common(locale).Scrimmage;
    case CALENDAR_ENTRY_TYPES.other:
      return translate.common(locale).Other;
  }
}

export function getPrettyGenderTitle(gender: GENDERS, locale: string) {
  switch (gender) {
    case GENDERS.boys:
      return translate.common(locale).Boys;
    case GENDERS.girls:
      return translate.common(locale).Girls;
    case GENDERS.mixed:
      return translate.common(locale).Mixed;
  }
}

export function getTranslatedDayOfWeek(dayOfWeek: string, locale: string): string {
  if (dayOfWeek.toLowerCase().includes("mon")) {
    return translate.common(locale).Monday;
  }
  if (dayOfWeek.toLowerCase().includes("tue")) {
    return translate.common(locale).Tuesday;
  }
  if (dayOfWeek.toLowerCase().includes("wed")) {
    return translate.common(locale).Wednesday;
  }
  if (dayOfWeek.toLowerCase().includes("thu")) {
    return translate.common(locale).Thursday;
  }
  if (dayOfWeek.toLowerCase().includes("fri")) {
    return translate.common(locale).Friday;
  }
  if (dayOfWeek.toLowerCase().includes("sat")) {
    return translate.common(locale).Saturday;
  }
  if (dayOfWeek.toLowerCase().includes("sun")) {
    return translate.common(locale).Sunday;
  }
  return dayOfWeek;
}

function getEdgeCachedURL(url: string, cacheDays: number) {
  const cacheTTL = 60 * 60 * 24 * cacheDays;
  const encodedUrl = encodeURIComponent(url);
  return `https://asset-reverse-proxy.olliesports.workers.dev/?cacheTTL=${cacheTTL}&url=${encodedUrl}`;
}
