import linkifyHtml from "linkify-html";

export function linkify(str: string) {
  return linkifyHtml(str, {
    className: "text-blue-500 underline",
    target: "_blank"
  });
}

export function htmlify(str: string) {
  return linkify(str.replace(/\n/g, "<br>"));
}
