import { OrgFeatures, TEAM_SPORT } from "@ollie-sports/models";

export function getDefaultEnabledFeaturesForOrg(p: { teamSport: TEAM_SPORT }): { [feature in OrgFeatures]?: true } {
  const enabledFeatures: {
    [feature in OrgFeatures]?: true;
  } = {};
  switch (p.teamSport) {
    case TEAM_SPORT.soccer:
      enabledFeatures[OrgFeatures.orgDirectory] = true;
      enabledFeatures[OrgFeatures.stats] = true;
      break;
    case TEAM_SPORT.americanFootball:
    case TEAM_SPORT.baseball:
    case TEAM_SPORT.basketball:
    case TEAM_SPORT.hockey:
    case TEAM_SPORT.lacrosse:
    case TEAM_SPORT.softball:
    case TEAM_SPORT.volleyball:
    case TEAM_SPORT.waterPolo:
  }
  return enabledFeatures;
}

// i18n certified - complete
