export async function downsampleAndCropImage(
  file: File,
  targetSize: number,
  outputFormat = "image/jpeg",
  outputQuality = 0.9
): Promise<Blob> {
  // Check that the file is actually an image
  if (!file.type.startsWith("image/")) {
    throw new Error("The selected file is not an image.");
  }

  // Load the image blob as an HTMLImageElement
  const image = await loadImage(file);

  // Determine the aspect ratio of the image
  const aspectRatio = image.height / image.width;

  // Downsample the image to the target size
  const downscaledImage = downscaleImage(image, targetSize, aspectRatio);

  // Compute the coordinates of the crop area
  const cropSize = Math.min(downscaledImage.width, downscaledImage.height);
  const cropX = (downscaledImage.width - cropSize) / 2;
  const cropY = (downscaledImage.height - cropSize) / 2;

  // Crop the downscaled image to a centered square
  const croppedImage = cropImage(downscaledImage, cropX, cropY, cropSize, cropSize);

  // Convert the cropped image to a Blob
  const blob = await imageToBlob(croppedImage, outputFormat, outputQuality);

  return blob;
}

function loadImage(file: File): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => resolve(image);
    image.onerror = reject;
  });
}

function downscaleImage(image: HTMLImageElement, targetWidth: number, aspectRatio: number): HTMLCanvasElement {
  const canvas = document.createElement("canvas");
  canvas.width = targetWidth;
  canvas.height = targetWidth * aspectRatio;

  const ctx = canvas.getContext("2d")!;
  ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

  return canvas;
}

function cropImage(
  image: HTMLCanvasElement,
  cropX: number,
  cropY: number,
  cropWidth: number,
  cropHeight: number
): HTMLCanvasElement {
  const canvas = document.createElement("canvas");
  canvas.width = cropWidth;
  canvas.height = cropHeight;

  const ctx = canvas.getContext("2d")!;
  ctx.drawImage(image, -cropX, -cropY);

  return canvas;
}

function imageToBlob(canvas: HTMLCanvasElement, outputFormat: string, outputQuality: number): Promise<Blob> {
  return new Promise(resolve => {
    canvas.toBlob(blob => resolve(blob!), outputFormat, outputQuality);
  });
}
