import { dateFormatters } from "@ollie-sports/i18n";
import { OrgSeason } from "@ollie-sports/models";
import moment from "moment";

export function getOrgSeasonLabel(orgSeason: OrgSeason, locale: string) {
  return `${dateFormatters.mm_dd_yyyy(moment(orgSeason.startDateMS).toDate(), locale)} - ${dateFormatters.mm_dd_yyyy(
    moment(orgSeason.endDateMS).toDate(),
    locale
  )}`;
}
