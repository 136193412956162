export type BehaviorSubject<T> = {
  get: () => T;
  set: (newVal: T) => void;
  onChange: (fn: (newVal: T) => void) => () => void;
};

//A simple Behavir Subject. AKA an event stream that always has a "current value".
export function createBehaviorSubject<T>(initVal: T): BehaviorSubject<T> {
  let val = initVal;

  const listeners: Map<Function, (v: T) => void> = new Map();

  function get() {
    return val;
  }

  function set(newVal: T) {
    val = newVal;
    listeners.forEach(fn => {
      fn(val);
    });
  }

  function onChange(fn: (v: T) => void): () => void {
    listeners.set(fn, fn);
    return function unsubscribe() {
      listeners.delete(fn);
    };
  }

  return {
    get,
    set,
    onChange
  };
}
// i18n certified - complete
