import express from "express";
import {
  AccountPrivate,
  CONVERSATION_TYPES,
  OpenOrgEvent,
  OpenOrgEventRegistration,
  OrgId,
  OrgTeamTagId,
  Team,
  TeamId,
  __ConversationOrg
} from "@ollie-sports/models";
import { BatchTask } from "@ollie-sports/firebase";
import _ from "lodash";
import { getUniversalHelpers } from "../helpers";
import { ObjectKeys } from "./object-keys";

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export async function getBatchTasksToUpdateTagDependenciesOnDeletedTag(p: { orgTeamTagId: OrgTeamTagId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const batchTasks: BatchTask[] = [];

  const [
    openOrgEventsData,
    openOrgEventRegistrationsData,
    orgConversationsData,
    accountPrivateData,
    orgRegistrationPackagesData
  ] = await Promise.all([
    h.OpenOrgEvent.query({
      where: [
        {
          orgTeamTagId: ["==", p.orgTeamTagId]
        }
      ]
    }),
    h.OpenOrgEventRegistration.query({
      where: [
        {
          tryoutInfo: {
            sessionSelection: {
              orgTeamTagId: ["==", p.orgTeamTagId]
            }
          }
        }
      ],
      limit: 5000
    }),
    h.Conversation.query({
      where: [
        {
          orgFilters: {
            orgTeamTagIds: { [p.orgTeamTagId]: ["==", true] }
          }
        }
      ]
    }),
    h.AccountPrivate.query({
      where: [
        {
          settings: {
            eventFilters: {
              teamOrgTagIds: { [p.orgTeamTagId]: ["==", true] }
            }
          }
        }
      ]
    }),
    h.OrgRegistrationPackage.query({
      where: [
        {
          orgTeamTagIds: { [p.orgTeamTagId]: ["==", true] }
        }
      ]
    })
  ]);

  const teamsData = await h.Team.query({ where: [{ assignedOrgTagIds: { [p.orgTeamTagId]: ["==", true] } }] });

  const openOrgEvents = openOrgEventsData.docs;
  const openOrgEventRegistrations = openOrgEventRegistrationsData.docs;
  const orgConversations = orgConversationsData.docs;
  const accountPrivates = accountPrivateData.docs;
  const orgRegistrationPackages = orgRegistrationPackagesData.docs;
  const teams = teamsData?.docs ?? [];

  for (let i = 0; i < openOrgEvents.length; i++) {
    const event = openOrgEvents[i];
    batchTasks.push(
      await h.OpenOrgEvent.update({ id: event.id, doc: { orgTeamTagId: h._MagicDeleteValue } }, { returnBatchTask: true })
    );
  }

  for (let i = 0; i < openOrgEventRegistrations.length; i++) {
    const registration = openOrgEventRegistrations[i];
    batchTasks.push(
      await h.OpenOrgEventRegistration.update(
        {
          id: registration.id,
          doc: { tryoutInfo: { sessionSelection: { orgTeamTagId: h._MagicDeleteValue } }, updatedAtMS: Date.now() }
        },
        { returnBatchTask: true }
      )
    );
  }

  for (let i = 0; i < orgConversations.length; i++) {
    const conversation = orgConversations[i];

    if (conversation.conversationType === CONVERSATION_TYPES.org) {
      if (ObjectKeys(conversation.orgFilters?.orgTeamTagIds ?? {}).length === 1) {
        batchTasks.push(await h.Conversation.delete({ id: conversation.id }, { returnBatchTask: true }));
      } else {
        batchTasks.push(
          await h.Conversation.update(
            {
              id: conversation.id,
              doc: { orgFilters: { orgTeamTagIds: { [p.orgTeamTagId]: h._MagicDeleteValue } } }
            },
            { returnBatchTask: true }
          )
        );
      }
    }
  }

  for (let i = 0; i < teams.length; i++) {
    const team = teams[i];
    batchTasks.push(
      await h.Team.update(
        { id: team.id, doc: { assignedOrgTagIds: { [p.orgTeamTagId]: h._MagicDeleteValue } } },
        { returnBatchTask: true }
      )
    );
  }

  for (let i = 0; i < accountPrivates.length; i++) {
    const accountPrivate = accountPrivates[i];
    batchTasks.push(
      await h.AccountPrivate.update(
        {
          id: accountPrivate.id,
          doc: { settings: { eventFilters: { teamOrgTagIds: { [p.orgTeamTagId]: h._MagicDeleteValue } } } }
        },
        { returnBatchTask: true }
      )
    );
  }

  for (let i = 0; i < orgRegistrationPackages.length; i++) {
    const orgRegistrationPackage = orgRegistrationPackages[i];
    batchTasks.push(
      await h.OrgRegistrationPackage.update(
        {
          id: orgRegistrationPackage.id,
          doc: { orgTeamTagIds: { [p.orgTeamTagId]: h._MagicDeleteValue } }
        },
        { returnBatchTask: true }
      )
    );
  }

  return batchTasks;
}
