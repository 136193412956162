/***
 *
 * NOTE: PIFI stands for Personally Identifiable Financial Information.
 * This includes credit card information, bank account info, social security numbers, and merchant secrets
 *
 ***/

import { getServerHelpers } from "../helpers";

const algorithm = "aes-256-cbc";
const hmacAlgorithm = "sha256";
const keyLength = 32;
const ivLength = 16;

const DEV_ENCRYPTION_KEY = "vrFfQGdyyXyCOp4WlTGi8L5QFP4oFiSjgr4xki2jsGo=";

export async function encryptPIFI(data: string): Promise<string> {
  // SERVER_ONLY_TOGGLE
  const sharedSecret = getServerHelpers().serverConfig.projectId.match(/dev/)
    ? DEV_ENCRYPTION_KEY
    : process.env.PIFI_ENCRYPTION_KEY;

  if (!sharedSecret) {
    throw new Error("Unable to find env variable PIFI_ENCRYPTION_KEY! Ensure it is supplied when starting this server process");
  }
  const { createCipheriv, randomBytes, scrypt, createHmac } = getServerHelpers().injectedServerLibraries.crypto;
  const iv = randomBytes(ivLength);
  const key = (await promisify(scrypt)(sharedSecret, "salt", keyLength, {})) as Buffer;

  const cipher = createCipheriv(algorithm, key, iv);
  let encrypted = cipher.update(data, "utf8", "hex");
  encrypted += cipher.final("hex");

  // Generate HMAC for the encrypted data
  const hmac = createHmac(hmacAlgorithm, key);
  hmac.update(encrypted);
  const digest = hmac.digest("hex");

  // The output format is IV + encrypted data + HMAC
  return [iv.toString("hex"), encrypted, digest].join("");
  // SERVER_ONLY_TOGGLE
}

export async function decryptPIFI(data: string): Promise<string> {
  // SERVER_ONLY_TOGGLE
  const sharedSecret = getServerHelpers().serverConfig.projectId.match(/dev/)
    ? DEV_ENCRYPTION_KEY
    : process.env.PIFI_ENCRYPTION_KEY;

  if (!sharedSecret) {
    throw new Error("Unable to find env variable PIFI_ENCRYPTION_KEY! Ensure it is supplied when starting this server process");
  }
  const { createDecipheriv, scrypt, createHmac } = getServerHelpers().injectedServerLibraries.crypto;
  const key = (await promisify(scrypt)(sharedSecret, "salt", keyLength, {})) as Buffer;

  // Extract components
  const iv = Buffer.from(data.slice(0, ivLength * 2), "hex");
  const hmacDigestSize = 64; // Length of HMAC digest in hex format for sha256
  const encryptedData = data.slice(ivLength * 2, data.length - hmacDigestSize);
  const hmacDigest = data.slice(data.length - hmacDigestSize);

  // Verify HMAC
  const hmac = createHmac(hmacAlgorithm, key);
  hmac.update(encryptedData);
  const recalculatedDigest = hmac.digest("hex");

  if (recalculatedDigest !== hmacDigest) {
    throw new Error("Data integrity check failed");
  }

  // Decrypt data
  const decipher = createDecipheriv(algorithm, key, iv);
  let decrypted = decipher.update(encryptedData, "hex", "utf8");
  decrypted += decipher.final("utf8");

  return decrypted;
  // SERVER_ONLY_TOGGLE
}

function promisify<T extends (...args: any[]) => any>(fn: T): (...funcArgs: ParametersExceptLast<T>) => Promise<any> {
  return (...funcArgs: any[]): Promise<any> => {
    return new Promise((resolve, reject) => {
      fn(...funcArgs, (err: Error | null, result?: any) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  };
}

type ParametersExceptLast<T> = T extends (...args: infer P) => any ? (P extends [...infer Head, any] ? Head : never) : never;
