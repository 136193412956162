import {
  AccountId,
  AccountSecretPaymentMethodId,
  CCFailureCodes,
  OrgCoupon,
  OrgCouponId,
  OrgId,
  OrgInvoice,
  OrgInvoiceChild,
  OrgInvoiceId,
  OrgInvoiceTypes,
  OrgPaymentInvoiceDefault,
  OrgPaymentInvoiceFailed,
  OrgPaymentType,
  PaymentMethod,
  PaymentMethodSnapshot,
  PaymentMethodType,
  PlayerBundleId,
  NMIPaymentResponseInfo,
  isKeyOfNMIResponseCodes,
  NMIPaymentResponseInfo__Success,
  NMIPaymentResponseInfo__Failure,
  OrgPaymentInvoice,
  PaymentMethodOnCreation
} from "@ollie-sports/models";
import { translate } from "@ollie-sports/i18n";
import { BatchTask } from "@ollie-sports/firebase";
import moment from "moment";
import { getServerHelpers, getUniversalHelpers } from "../helpers";
import { generateOrgPaymentId } from "./org-payment-utils";
import { OlliePipe } from "@ollie-sports/pipe";
import { getDefaultPaymentAccount } from "../compute/account.compute";
import { notifyOfExtremelyImportantError } from "./slack-utils";
import { nmiSDK } from "./nmiSDK";
import { PRETTY_PAYMENT_ERROR_CODES } from "../constants";
import { translateServer } from "./serverTranslate";
import _ from "lodash";
import { getRefundAvailabilityDetails } from "./org-invoice-utils";
import { formatMoneyCentsToDollarCentPrettyString } from "./number-strings";

export type ChargeCardUnexpectedErrorStatusCodes =
  | "charge-card-for-invoice-completely-mysterious-error"
  | "charge-card-completely-mysterious-error"
  | "charge-card-no-payment-method"
  | "charge-card-payment-method-not-found-in-basis-theory"
  | "charge-card-failed-invoice-batch-tasks-error"
  | "charge-card-invoice-success-batch-tasks-error"
  | "charge-card-creating-new-customer-without-account"
  | "charge-card-finding-or-creating-customer"
  | "charge-card-processing-error"
  | "charge-card-coupon-update-error"
  | "charge-card-mysterious-authorize-error"
  | "charge-card-unexpected-processing-error"
  | "charge-card-missing-customer-info"
  | "not-really-unexpected-just-logging"
  | "charge-card-payment-method-not-card-or-bank-in-basis-theory";

export type PayInvoiceUnexpectedErrorStatusCodes =
  | "pay-invoice-completely-mysterious-error"
  | "pay-invoice-notifications-error"
  | "pay-invoice-failed-invoice-notifications-error"
  | "pay-invoice-cant-find-player-bundle"
  | "pay-invoice-cant-find-org"
  | "pay-invoice-cant-find-payment-plan"
  | "pay-invoice-cant-find-package"
  | "pay-invoice-batch-tasks-error"
  | "pay-invoice-charge-greater-than-max"
  | "pay-individual-invoice-failed-invoice-notifications-error"
  | "pay-individual-invoice-batch-tasks-error"
  | "pay-individual-invoice-completely-mysterious-error"
  | "pay-individual-invoice-notification-error"
  | "pay-individual-invoice-email-confirmation-error"
  | "pay-individual-invoice-already-paid";

export type RefundUnexpectedErrorStatusCodes =
  | "refund-completely-mysterious-error"
  | "refund-refund-amount-too-high-for-some-reason"
  | "refund-processing-error"
  | "refund-nmi-only-processing-error"
  | "refund-unknown-reason";

export type UnexpectedNMIErrors =
  | "nmi-low-level-error"
  | "nmi-problem-voiding-echeck-auth"
  | "nmi-unknown-verify-bank-account-error"
  | "nmi-problem-voiding-cc-auth"
  | "nmi-unknown-verify-bank-account-error"
  | "nmi-lock-time-exceeded"
  | "nmi-socket-hang-up"
  | "nmi-rate-limit-hit";

export type UnexpectedCronErrors = "cron-unable-to-process-invoice" | "cron-top-level-process-scheduled-payments";

export async function logUnexpectedPaymentError(p: {
  errorCode:
    | ChargeCardUnexpectedErrorStatusCodes
    | PayInvoiceUnexpectedErrorStatusCodes
    | RefundUnexpectedErrorStatusCodes
    | UnexpectedNMIErrors
    | UnexpectedCronErrors;
  locationId: string;
  accountId: string;
  paymentInfo: PaymentMethod | PaymentMethodSnapshot | OrgPaymentInvoice | PaymentMethodOnCreation | null | undefined;
  info: any;
  olliePipe: OlliePipe;
  severity?: "low" | "medium" | "high";
}) {
  const info = p.info instanceof Error ? { message: p.info.message, stack: p.info.stack } : p.info;
  await notifyOfExtremelyImportantError({
    message: `Unexpected payment error at ${p.locationId}!\n\n${JSON.stringify(
      { info, accountId: p.accountId, paymentInfo: p.paymentInfo },
      null,
      2
    )}`,
    channel: "#important-errors"
  }).catch(e => {
    console.error("Did not send important payment error to on call!", e);
  });

  await p.olliePipe
    .emitEvent(
      {
        type: "error-unexpected-payments-error",
        payload: {
          errorCode: p.errorCode,
          info,
          accountId: p.accountId,
          paymentInfo: p.paymentInfo,
          locationId: p.locationId
        }
      },
      { sendImmediate: true, accountId: p.accountId }
    )
    .catch(e => {
      console.error("Did not write important payment ollie pipe error!", e);
    });
}

export async function chargeWithNMIForInvoice(p: {
  accountIdToBeCharged: AccountId;
  nonDefaultPaymentMethodIdToUse?: string;
  orgId: OrgId;
  baseIdempotencyKey: string;
  paymentDetails: {
    baseAmountDueCents: number;
    lateFeeAmountDueCents?: number;
    otherFeesAmountDueCents: number;
    appliedOrgCoupon?: OrgCoupon;
  };
  isManualChildInvoiceTrigger?: boolean;
  locale: string;
  orgInvoice: OrgInvoice;
  invoiceGroupId: OrgInvoiceId;
}): Promise<
  | { type: "error"; errorCode: ChargeCardUnexpectedErrorStatusCodes; prettyErrorReason: string }
  | {
      type: "success";
      orgPayment: OrgPaymentInvoiceDefault;
    }
  | {
      type: "failed";
      orgPayment?: OrgPaymentInvoiceFailed;
      prettyFailureReason: string;
    }
> {
  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();
  const nowMS = Date.now();

  type Unwrap<T> = T extends Promise<infer U> ? U : T;

  let chargeResponse: Unwrap<ReturnType<typeof chargeWithNMI>> | null = null;

  try {
    chargeResponse = await chargeWithNMI(p);

    if (chargeResponse.type === "error") {
      return chargeResponse;
    }

    if (chargeResponse.type === "zeroDollarPayment") {
      const orgPayment: OrgPaymentInvoiceDefault = {
        id: generateOrgPaymentId(),
        accountId: p.accountIdToBeCharged,
        type: OrgPaymentType.invoiceDefault,
        status: "succeeded",
        amountCents: Math.floor(p.paymentDetails.baseAmountDueCents),
        lateFeeAmountCents: p.paymentDetails.lateFeeAmountDueCents
          ? Math.floor(p.paymentDetails.lateFeeAmountDueCents)
          : undefined,
        processingFeeAmountCents: Math.floor(p.paymentDetails.otherFeesAmountDueCents),
        createdAtMS: nowMS,
        invoiceGroupId: p.invoiceGroupId,
        invoiceId: p.orgInvoice.id,
        orgId: p.orgId,
        playerBundleId: p.orgInvoice.playerBundleId
      };

      try {
        await h.OrgPayment.add({ doc: orgPayment });
      } catch (e) {
        // ERROR TODO
      }

      if (p.paymentDetails.appliedOrgCoupon) {
        try {
          await h.OrgCoupon.update({
            id: p.paymentDetails.appliedOrgCoupon.id,
            doc: {
              numberTimesUsed: (p.paymentDetails.appliedOrgCoupon.numberTimesUsed ?? 0) + 1,
              numberTimesUsedByPlayerBundleId: {
                [p.orgInvoice.playerBundleId]:
                  (p.paymentDetails.appliedOrgCoupon.numberTimesUsedByPlayerBundleId?.[p.orgInvoice.playerBundleId] ?? 0) + 1
              }
            }
          });
        } catch (e) {
          logUnexpectedPaymentError({
            errorCode: "charge-card-coupon-update-error",
            olliePipe,
            paymentInfo: null,
            accountId: p.accountIdToBeCharged,
            locationId: "asd234kjkd",
            info: e
          });
        }
      }

      //EARLY RETURN FOR $0 CHARGE
      return { type: "success", orgPayment };
    }

    if (chargeResponse.type === "failed") {
      const batchTasks: BatchTask[] = [];
      let orgPayment: OrgPaymentInvoiceFailed | undefined = undefined;
      if (p.orgInvoice.type !== OrgInvoiceTypes.registration) {
        orgPayment = {
          id: generateOrgPaymentId(),
          accountId: p.accountIdToBeCharged,
          failureCode: chargeResponse.nmiResponseData.responseCode || null,
          failureFallbackText: chargeResponse.nmiResponseData.responseText || null,
          type: OrgPaymentType.invoiceFailedPayment,
          amountCents: Math.floor(p.paymentDetails.baseAmountDueCents),
          lateFeeAmountCents: p.paymentDetails.lateFeeAmountDueCents
            ? Math.floor(p.paymentDetails.lateFeeAmountDueCents)
            : undefined,
          processingFeeAmountCents: Math.floor(p.paymentDetails.otherFeesAmountDueCents),
          createdAtMS: nowMS,
          invoiceGroupId: p.invoiceGroupId,
          invoiceId: p.orgInvoice.id,
          orgId: p.orgId,
          playerBundleId: p.orgInvoice.playerBundleId,
          nmiPaymentResponseInfo: chargeResponse.nmiResponseData,
          status: "failed",
          paymentMethodSnapshot: chargeResponse.paymentMethodSnapshot
        };

        batchTasks.push(
          await h.OrgPayment.add(
            {
              doc: orgPayment
            },
            { returnBatchTask: true }
          )
        );
      }

      if (
        (p.orgInvoice.type === OrgInvoiceTypes.manualPaymentPlanInstallment ||
          p.orgInvoice.type === OrgInvoiceTypes.registrationPaymentPlanInstallment) &&
        !p.isManualChildInvoiceTrigger
      ) {
        const updatedOrgInvoice: OrgInvoiceChild = { ...p.orgInvoice };
        const numPreviousFailedAttempts = updatedOrgInvoice.numberOfFailedPaymentAttempts ?? 0;
        updatedOrgInvoice.previousFailedPaymentAttemptMS = nowMS;
        updatedOrgInvoice.numberOfFailedPaymentAttempts = numPreviousFailedAttempts + 1;
        if (numPreviousFailedAttempts < 4) {
          updatedOrgInvoice.failedPaymentScheduledRetryMS = moment().add(1, "day").valueOf();
        }

        batchTasks.push(
          await h.OrgInvoice.update(
            {
              id: p.orgInvoice.id,
              doc: updatedOrgInvoice
            },
            { returnBatchTask: true }
          )
        );
      }
      try {
        await h._BatchRunner.executeBatch(batchTasks);
      } catch (e) {
        logUnexpectedPaymentError({
          errorCode: "charge-card-failed-invoice-batch-tasks-error",
          olliePipe,
          paymentInfo: chargeResponse.paymentMethodSnapshot,
          accountId: p.accountIdToBeCharged,
          info: e,
          locationId: "234kdjfkj45"
        });
        return {
          type: "error",
          prettyErrorReason: translate({
            defaultMessage:
              "Something went wrong. Please do not submit again or you may be double charged. Please contact us at support@olliesports.com and we will resolve the situation.",
            serverLocale: p.locale
          }),
          errorCode: "charge-card-failed-invoice-batch-tasks-error"
        };
      }
      olliePipe.emitEvent({
        type: "metric-handled-failed-payment",
        payload: {
          chargeResponse,
          orgInvoice: p.orgInvoice
        }
      });
      //EARLY RETURN FOR FAILED PAYMENT
      return { type: "failed", orgPayment, prettyFailureReason: chargeResponse.prettyFailureReason };
    }

    const orgPayment: OrgPaymentInvoiceDefault = {
      id: generateOrgPaymentId(),
      accountId: p.accountIdToBeCharged,
      type: OrgPaymentType.invoiceDefault,
      status: "succeeded",
      amountCents: Math.floor(p.paymentDetails.baseAmountDueCents),
      lateFeeAmountCents: p.paymentDetails.lateFeeAmountDueCents ? Math.floor(p.paymentDetails.lateFeeAmountDueCents) : undefined,
      processingFeeAmountCents: Math.floor(p.paymentDetails.otherFeesAmountDueCents),
      createdAtMS: chargeResponse.createdAtMS,
      invoiceId: p.orgInvoice.id,
      invoiceGroupId: p.invoiceGroupId,
      orgId: p.orgId,
      playerBundleId: p.orgInvoice.playerBundleId,
      nmiPaymentResponseInfo: chargeResponse.nmiPaymentInfo,
      paymentMethodSnapshot: chargeResponse.paymentMethodSnapshot
    };

    const batchTasks: BatchTask[] = [
      await h.OrgPayment.add(
        {
          doc: orgPayment
        },
        { returnBatchTask: true }
      )
    ];

    if (p.paymentDetails.appliedOrgCoupon) {
      batchTasks.push(
        await h.OrgCoupon.update(
          {
            id: p.paymentDetails.appliedOrgCoupon.id,
            doc: {
              numberTimesUsed: (p.paymentDetails.appliedOrgCoupon.numberTimesUsed ?? 0) + 1,
              numberTimesUsedByPlayerBundleId: {
                [p.orgInvoice.playerBundleId]:
                  (p.paymentDetails.appliedOrgCoupon.numberTimesUsedByPlayerBundleId?.[p.orgInvoice.playerBundleId] ?? 0) + 1
              }
            }
          },
          { returnBatchTask: true }
        )
      );
    }

    try {
      await h._BatchRunner.executeBatch(batchTasks);
    } catch (e) {
      logUnexpectedPaymentError({
        errorCode: "charge-card-invoice-success-batch-tasks-error",
        olliePipe,
        accountId: p.accountIdToBeCharged,
        paymentInfo: chargeResponse.paymentMethodSnapshot,
        info: e,
        locationId: "34ikj34kj4rtytmncx"
      });
      return {
        type: "error",
        prettyErrorReason: translate({
          defaultMessage:
            "Something went wrong. Please do not submit again or you may be double charged. Please contact us at support@olliesports.com and we will resolve the situation.",
          serverLocale: p.locale
        }),
        errorCode: "charge-card-invoice-success-batch-tasks-error"
      };
    }

    return { type: "success", orgPayment };
  } catch (e) {
    logUnexpectedPaymentError({
      errorCode: "charge-card-for-invoice-completely-mysterious-error",
      olliePipe,
      paymentInfo: chargeResponse && "paymentMethodSnapshot" in chargeResponse ? chargeResponse.paymentMethodSnapshot : null,
      accountId: p.accountIdToBeCharged,
      info: e,
      locationId: "kjk6n54nmcdei"
    });
    return {
      type: "error",
      prettyErrorReason: translate({
        defaultMessage:
          "Something went wrong. Please do not submit again or you may be double charged. Please contact us at support@olliesports.com and we will resolve the situation.",
        serverLocale: p.locale
      }),
      errorCode: "charge-card-for-invoice-completely-mysterious-error"
    };
  }
}

export async function chargeWithNMI(p: {
  accountIdToBeCharged: AccountId;
  nonDefaultPaymentMethodIdToUse?: AccountSecretPaymentMethodId;
  orgId: OrgId;
  baseIdempotencyKey: string;
  paymentDetails: {
    baseAmountDueCents: number;
    lateFeeAmountDueCents?: number;
    otherFeesAmountDueCents: number;
    appliedOrgCoupon?: OrgCoupon;
  };
  locale: string;
  note?: string;
  testPaymentOverrideDetails?: {
    paymentMethodToUse: PaymentMethod;
  };
}): Promise<
  | {
      type: "success";
      nmiPaymentInfo: NMIPaymentResponseInfo__Success;
      createdAtMS: number;
      paymentMethodSnapshot?: PaymentMethodSnapshot;
    }
  | {
      type: "failed";
      prettyFailureReason: string;
      paymentMethodSnapshot?: PaymentMethodSnapshot;
      nmiResponseData: NMIPaymentResponseInfo__Failure;
    }
  | { type: "error"; errorCode: ChargeCardUnexpectedErrorStatusCodes; prettyErrorReason: string }
  | { type: "zeroDollarPayment" }
> {
  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();

  let paymentMethodToUse: PaymentMethod | null | undefined = undefined;

  try {
    const totalAmountCents =
      p.paymentDetails.baseAmountDueCents +
      (p.paymentDetails.lateFeeAmountDueCents ?? 0) +
      p.paymentDetails.otherFeesAmountDueCents;

    if (totalAmountCents < 1) {
      //EARLY RETURN FOR $0 CHARGE
      return { type: "zeroDollarPayment" };
    }

    if (p.testPaymentOverrideDetails) {
      paymentMethodToUse = p.testPaymentOverrideDetails.paymentMethodToUse;
    } else {
      const [accountSecret] = await Promise.all([h.AccountSecret.getDoc(p.accountIdToBeCharged)]);

      paymentMethodToUse = p.nonDefaultPaymentMethodIdToUse
        ? accountSecret?.paymentMethodsById?.[p.nonDefaultPaymentMethodIdToUse]
        : getDefaultPaymentAccount(Object.values(accountSecret?.paymentMethodsById ?? {}));
    }

    if (!paymentMethodToUse) {
      logUnexpectedPaymentError({
        errorCode: "charge-card-no-payment-method",
        olliePipe,
        accountId: p.accountIdToBeCharged,
        paymentInfo: null,
        info: null,
        locationId: "k34j5kjs8234kljKDkjer"
      });
      return {
        type: "error",
        prettyErrorReason: translate({
          defaultMessage: "Cannot find a payment method. Ensure you have added a payment method.",
          serverLocale: p.locale
        }),
        errorCode: "charge-card-no-payment-method"
      };
    }

    try {
      const safePaymentMethodToUse = paymentMethodToUse;

      const doRetryingPaymentRequest = (count = 0): Promise<NMIPaymentResponseInfo> => {
        return (
          safePaymentMethodToUse.type === PaymentMethodType.card
            ? nmiSDK.chargeCC({
                amountCents: totalAmountCents,
                chargingOrgId: p.orgId,
                encryptedCardNumber: safePaymentMethodToUse.encryptedCardNumber,
                expMM: safePaymentMethodToUse.expMM,
                expYYYY: safePaymentMethodToUse.expYYYY,
                fullName: safePaymentMethodToUse.ownerName,
                postalCode: safePaymentMethodToUse.postalCode,
                idempotencyKey: p.baseIdempotencyKey + safePaymentMethodToUse.encryptedCardNumber,
                accountId: p.accountIdToBeCharged
              })
            : nmiSDK.chargeBankAccount({
                accountType: safePaymentMethodToUse.bankAccountType,
                amountCents: totalAmountCents,
                chargingOrgId: p.orgId,
                encryptedAccountNumber: safePaymentMethodToUse.encryptedAccountNumber,
                fullName: safePaymentMethodToUse.ownerName,
                routingNumber: safePaymentMethodToUse.routingNumber,
                idempotencyKey:
                  p.baseIdempotencyKey + safePaymentMethodToUse.encryptedAccountNumber + safePaymentMethodToUse.routingNumber,
                accountId: p.accountIdToBeCharged
              })
        ).catch(async e => {
          if (count === 0) {
            await new Promise(res => setTimeout(res, 1000));
            return doRetryingPaymentRequest(1);
          } else {
            throw e;
          }
        });
      };

      var nmiResponseData = await doRetryingPaymentRequest();
    } catch (e) {
      logUnexpectedPaymentError({
        errorCode: "charge-card-unexpected-processing-error",
        locationId: "k3j4lkjfd980234kmaserkop",
        accountId: p.accountIdToBeCharged,
        olliePipe,
        paymentInfo: paymentMethodToUse,
        info: e
      });
      return {
        type: "error",
        prettyErrorReason: translate({
          defaultMessage:
            "There was an error processing your payment. You have not been charged. Please try again. If the issue persists, please contact us at support@olliesports.com",
          serverLocale: p.locale
        }),
        errorCode: "charge-card-unexpected-processing-error"
      };
    }

    if (nmiResponseData.status === "success") {
      return {
        type: "success",
        createdAtMS: Date.now(),
        nmiPaymentInfo: nmiResponseData,
        paymentMethodSnapshot:
          paymentMethodToUse.type === PaymentMethodType.bank
            ? { ...paymentMethodToUse, encryptedAccountNumber: null }
            : { ...paymentMethodToUse, encryptedCardNumber: null }
      };
    } else if (nmiResponseData.status === "failure") {
      await olliePipe.emitEvent({
        type: "error-charging-with-nmi",
        payload: {
          accountId: p.accountIdToBeCharged,
          nmiInfo: nmiResponseData,
          paymentMethodToUse: {
            ...paymentMethodToUse,
            encryptedCardNumber: null
          },
          paymentDetails: p.paymentDetails
        }
      });

      return {
        type: "failed",
        prettyFailureReason: translate(
          {
            defaultMessage:
              "There was a problem processing your payment ({reason}). You have not been charged. Please update your payment method and try again.",
            serverLocale: p.locale
          },
          {
            reason:
              PRETTY_PAYMENT_ERROR_CODES[nmiResponseData.responseCode || ""]?.(p.locale) ||
              nmiResponseData.responseText ||
              translateServer({ defaultMessage: "Unknown Error", serverLocale: p.locale })
          }
        ),
        paymentMethodSnapshot:
          paymentMethodToUse.type === PaymentMethodType.bank
            ? { ...paymentMethodToUse, encryptedAccountNumber: null }
            : { ...paymentMethodToUse, encryptedCardNumber: null },
        nmiResponseData
      };
    } else {
      await logUnexpectedPaymentError({
        errorCode: "charge-card-unexpected-processing-error",
        olliePipe,
        accountId: p.accountIdToBeCharged,
        paymentInfo: paymentMethodToUse,
        info: nmiResponseData,
        locationId: "343kjKE45jkdsKl3"
      });

      return {
        type: "error",
        prettyErrorReason: translate({
          defaultMessage:
            "There was an error processing your payment. You have not been charged. Please try again. If the issue persists, please contact us at support@olliesports.com",
          serverLocale: p.locale
        }),
        errorCode: "charge-card-unexpected-processing-error"
      };
    }
  } catch (e) {
    logUnexpectedPaymentError({
      errorCode: "charge-card-completely-mysterious-error",
      olliePipe,
      accountId: p.accountIdToBeCharged,
      paymentInfo: paymentMethodToUse,
      info: e,
      locationId: "234kljwlkehmnb56n6b7980809"
    });
    return {
      type: "error",
      prettyErrorReason: translate({
        defaultMessage:
          "Something went wrong. Please do not submit again or you may be double charged. Please contact us at support@olliesports.com and we will resolve the situation.",
        serverLocale: p.locale
      }),
      errorCode: "charge-card-completely-mysterious-error"
    };
  }
}

export async function getNMITransactionDataForAccountId(p: { accountId: string; orgIds: string[] }) {
  return _(
    await Promise.all(
      _.uniq(p.orgIds).map(async orgId => {
        const res = await nmiSDK.queryTransactions({ orgId, account_id: p.accountId });
        return res.map(a => ({ ...a, orgId }));
      })
    )
  )
    .flatten()
    .uniqBy(a => a.transaction_id)
    .orderBy(a => Math.max(...a.action.map(b => b.date)), "desc")
    .value();
}

export async function issueRefund(p: {
  orgPaymentId: string;
  orgId: string;
  amountCents: number;
  reason: string;
  selfAccountId: string;
  locale: string;
}): Promise<{ status: "success" } | { status: "failed"; prettyFailureReason: string }> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h, olliePipe } = getUniversalHelpers();

  try {
    const [payment, priorRefunds, org] = await Promise.all([
      h.OrgPayment.getDoc(p.orgPaymentId),
      h.OrgPaymentRefund.query({
        where: [{ orgPaymentId: ["==", p.orgPaymentId] }]
      }).then(a => a.docs),
      h.Org.getDoc(p.orgId)
    ]);

    if (payment?.type !== OrgPaymentType.invoiceDefault || !payment.nmiPaymentResponseInfo || !payment.paymentMethodSnapshot) {
      throw new Error("May only refund actual payments!");
    }

    if (!org || !org?.accounts[p.selfAccountId]?.permissions.manageFinances) {
      throw new Error("Do not have permission to issue refunds!");
    }

    if (payment.orgId !== p.orgId) {
      throw new Error("Payment id must belong to the same organization!");
    }

    const { maxPossibleRefundCents, refundWindowIsOpen } = getRefundAvailabilityDetails({
      payment,
      refunds: priorRefunds
    });

    if (p.amountCents > maxPossibleRefundCents || p.amountCents <= 0) {
      return {
        prettyFailureReason: translate(
          { defaultMessage: "Unable to refund more than {maxPossibleRefund}.", serverLocale: p.locale },
          {
            maxPossibleRefund: formatMoneyCentsToDollarCentPrettyString(maxPossibleRefundCents)
          }
        ),
        status: "failed"
      };
    }

    if (!refundWindowIsOpen) {
      return {
        prettyFailureReason: translate({
          defaultMessage: "Unable to refund since more than a year has elapsed since this payment was processed.",
          serverLocale: p.locale
        }),
        status: "failed"
      };
    }

    try {
      var refundResp = await nmiSDK.refundTransaction({
        refundingOrgId: p.orgId,
        amountCents: p.amountCents,
        transactionId: payment.nmiPaymentResponseInfo.transactionId,
        type: payment.paymentMethodSnapshot.type === PaymentMethodType.bank ? "check" : "creditcard",
        idempotencyKey: p.orgId + p.orgPaymentId + p.reason + p.amountCents + p.selfAccountId,
        accountId: p.selfAccountId
      });
    } catch (e) {
      logUnexpectedPaymentError({
        errorCode: "refund-processing-error",
        olliePipe,
        info: e,
        locationId: "089sdklflkewjrlk34",
        accountId: p.selfAccountId,
        paymentInfo: payment
      });
      return {
        status: "failed",
        prettyFailureReason: translate({
          defaultMessage:
            "Unable to process refund. If the problem persists, please contact us at support@olliesports.com and we will help resolve the issue.",
          serverLocale: p.locale
        })
      };
    }

    if (refundResp.status === "success") {
      await h.OrgPaymentRefund.add({
        doc: {
          note: p.reason,
          orgId: p.orgId,
          orgInvoiceId: payment.invoiceId,
          orgPaymentId: p.orgPaymentId,
          totalAmountRefundedCents: p.amountCents,
          createdAtMS: Date.now(),
          refundedByAccountId: p.selfAccountId,
          nmiTransactionId: refundResp.transactionId,
          playerBundleId: payment.playerBundleId
        }
      });

      return { status: "success" };
    } else {
      if (refundResp.response === 3 && refundResp.responseText.includes("must be settled")) {
        return {
          status: "failed",
          prettyFailureReason: translate({
            serverLocale: p.locale,
            defaultMessage:
              "Your refund request was declined because the payment has not settled. Please try at a later date. If the issue persists, pleace contact us at support@olliesports.com"
          })
        };
      }
      await logUnexpectedPaymentError({
        errorCode: "refund-processing-error",
        olliePipe,
        accountId: p.selfAccountId,
        paymentInfo: payment,
        locationId: "09809234hnmasdf34",
        info: refundResp
      });
      return {
        status: "failed",
        prettyFailureReason: translate({
          serverLocale: p.locale,
          defaultMessage:
            "Your refund request was declined. The payment may not have settled and you should retry later. If the issue persists, pleace contact us at support@olliesports.com."
        })
      };
    }
  } catch (errRaw: unknown) {
    logUnexpectedPaymentError({
      errorCode: "refund-completely-mysterious-error",
      olliePipe,
      info: errRaw,
      paymentInfo: null,
      accountId: p.selfAccountId,
      locationId: "234090983488cjkldlk"
    });
    return {
      status: "failed",
      prettyFailureReason: translate({
        serverLocale: p.locale,
        defaultMessage:
          "Unable to process refund. If the problem persists, please contact us at support@olliesports.com and we will help resolve the issue."
      })
    };
  }
  // SERVER_ONLY_TOGGLE
}

export async function issueNMIRefundOnly(p: {
  selfAccountId: string;
  orgId: string;
  amountCents: number;
  transactionId: string;
  type: "check" | "creditcard";
  locale: string;
}) {
  const { olliePipe } = getUniversalHelpers();
  try {
    var refundResp = await nmiSDK.refundTransaction({
      refundingOrgId: p.orgId,
      amountCents: p.amountCents,
      transactionId: p.transactionId,
      type: p.type,
      idempotencyKey: "refund" + p.orgId + p.transactionId + p.amountCents + p.selfAccountId,
      accountId: p.selfAccountId
    });

    if (refundResp.status === "success") {
      return { status: "success" };
    } else {
      if (refundResp.response === 3 && refundResp.responseText.includes("must be settled")) {
        return {
          status: "failed",
          prettyFailureReason: translate({
            serverLocale: p.locale,
            defaultMessage:
              "Your refund request was declined because the payment has not settled. Please try at a later date. If the issue persists, pleace contact us at support@olliesports.com"
          })
        };
      }
      await logUnexpectedPaymentError({
        errorCode: "refund-nmi-only-processing-error",
        olliePipe,
        accountId: p.selfAccountId,
        paymentInfo: null,
        locationId: "09809234hnmasdf34",
        info: refundResp
      });
      return {
        status: "failed",
        prettyFailureReason: translate({
          serverLocale: p.locale,
          defaultMessage:
            "Your refund request was declined. The payment may not have settled and you should retry later. If the issue persists, pleace contact us at support@olliesports.com."
        })
      };
    }
  } catch (e) {
    logUnexpectedPaymentError({
      errorCode: "refund-nmi-only-processing-error",
      olliePipe,
      info: { ...(e as any), transactionId: p.transactionId },
      locationId: "089sdklflkewjrlk34",
      accountId: p.selfAccountId,
      paymentInfo: null
    });
    return {
      status: "failed",
      prettyFailureReason: translate({
        defaultMessage:
          "Unable to process refund. If the problem persists, please contact us at support@olliesports.com and we will help resolve the issue.",
        serverLocale: p.locale
      })
    };
  }
}
