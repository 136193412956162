//Basically copied from https://github.com/ai/nanoid/blob/main/index.browser.js
//But not cryptographically secure
function getRandomValues(bytes: number) {
  const array: number[] = [];
  for (let i = 0; i < bytes; i++) {
    array.push(Math.floor(Math.random() * 256));
  }
  return array;
}

export function customNanoid(length: number) {
  let alphabet = "23456789ABCDEFGHJKMNPQRSTUVWXYZ";
  let mask = (2 << (Math.log(alphabet.length - 1) / Math.LN2)) - 1;
  let step = -~((1.6 * mask * length) / alphabet.length);

  let id = "";
  while (true) {
    let bytes = getRandomValues(step);
    let j = step;
    while (j--) {
      id += alphabet[bytes[j] & mask] || "";
      if (id.length === length) return id;
    }
  }
}
