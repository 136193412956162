import { Team, OrgRegistrationPackage, TeamId, OrgSeason, OrgSeasonId, OrgTeamTag, TeamSettings } from "@ollie-sports/models";
import _ from "lodash";
import { ObjectKeys } from "./object-keys";

export type TeamOrgRegistrationPackageData = Record<string, OrgRegistrationPackage[]>;

type PartialRegistrationPackage = Omit<OrgRegistrationPackage, "orgId" | "createdAtMS">;

export function determineOrderedRegistrationPackageSuggestionsByTeamId(p: {
  teams: Team[];
  packages: PartialRegistrationPackage[];
  orgSeasonId: OrgSeasonId;
}) {
  const info: Record<string, { matchesPackage: boolean; pkg: PartialRegistrationPackage }[]> = {};

  // team-L8oZRCXoUmOZhVOt4XddH0Gva3UI

  p.teams.forEach(team => {
    info[team.id] = determineOrderedRegistrationPackageSuggestionsForTeam(team, p.packages, p.orgSeasonId);
  });

  return info;
}

export function determineOrderedRegistrationPackageSuggestionsForTeam(
  team: Team,
  registrationPackages: PartialRegistrationPackage[],
  orgSeasonId: OrgSeasonId
): { matchesPackage: boolean; pkg: PartialRegistrationPackage }[] {
  return _(registrationPackages.filter(pkg => pkg.orgSeasonId === orgSeasonId))
    .map(pkg => {
      const hasAtLeastOneRule = Object.keys(pkg.orgTeamTagIds || {}).length || Object.keys(pkg.birthYears || {}).length;

      const satisfiesTags = Object.keys(pkg.orgTeamTagIds || {}).length
        ? Object.keys(pkg.orgTeamTagIds || {}).every(teamTagId => team.assignedOrgTagIds?.[teamTagId])
        : true;
      const satisfiesBirthYear =
        team.birthYear && Object.keys(pkg.birthYears || {}).length ? !!pkg.birthYears![team.birthYear] : true;

      return {
        matchesPackage: !!(hasAtLeastOneRule && satisfiesBirthYear && satisfiesTags) || !hasAtLeastOneRule,
        pkg
      };
    })
    .sort((a, b) => {
      if (a.matchesPackage !== b.matchesPackage) {
        return Number(b.matchesPackage) - Number(a.matchesPackage);
      }

      const hasTeamIdA = Number(!!Object.keys(a.pkg.orgTeamTagIds || {}).length);
      const hasTeamIdB = Number(!!Object.keys(b.pkg.orgTeamTagIds || {}).length);

      if (hasTeamIdA !== hasTeamIdB) {
        return hasTeamIdB - hasTeamIdA;
      }

      const teamTagsA = Object.keys(a.pkg.orgTeamTagIds || {});
      const teamTagsB = Object.keys(b.pkg.orgTeamTagIds || {});
      const hasBirthYearsA = Number(!!Object.keys(a.pkg.birthYears || {}).length);
      const hasBirthYearsB = Number(!!Object.keys(b.pkg.birthYears || {}).length);

      if (_.difference(teamTagsA, teamTagsB).length === 0 && hasBirthYearsA !== hasBirthYearsB) {
        return hasBirthYearsB - hasBirthYearsA;
      }

      if (a.pkg.amountCents === b.pkg.amountCents && teamTagsA.length !== teamTagsB.length) {
        return teamTagsB.length - teamTagsA.length;
      }

      if (a.pkg.amountCents !== b.pkg.amountCents) {
        return b.pkg.amountCents - a.pkg.amountCents;
      }

      return b.pkg.name.localeCompare(a.pkg.name);
    })
    .value();
}

export function determineRegistrationPackagesForTeam(p: {
  team: Team;
  orgRegistrationPackages: OrgRegistrationPackage[];
}): TeamOrgRegistrationPackageData {
  // Group packages by season to find the matching package(s) for each season
  const results: TeamOrgRegistrationPackageData = {};

  // Group packages by season to find the matching package(s) for each season
  const orgRegistrationPackagesBySeason = _(p.orgRegistrationPackages)
    .groupBy(a => a.orgSeasonId)
    .value();

  // For each season, determine the winning package(s)
  for (let orgSeasonId in orgRegistrationPackagesBySeason) {
    const orgRegistrationPackagesForSeason = orgRegistrationPackagesBySeason[orgSeasonId];

    // Team matches a package if:
    // 1. The package has no orgTeamTagIds OR 2. We can't find an orgTeamTagId from the package that the team doesn't have
    // AND
    // 1. The package has no birthYears OR 2. The package includes the team's birth year
    const orgRegistrationPackagesThatTeamMatchesAllOrgTeamTagIdsAndBirthYear = orgRegistrationPackagesForSeason.filter(ofp => {
      const passesTheTagsTest =
        !ofp.orgTeamTagIds || !ObjectKeys(ofp.orgTeamTagIds).find(ottId => !p.team.assignedOrgTagIds?.[ottId]);
      const passesTheBirthYearTest = !ofp.birthYears || !!(p.team.birthYear && ofp.birthYears[p.team.birthYear]);
      return passesTheTagsTest && passesTheBirthYearTest;
    });

    // Figure out the most number of matching tags
    const maxNumberOfMatchingTags = orgRegistrationPackagesThatTeamMatchesAllOrgTeamTagIdsAndBirthYear.reduce((acc, ofp) => {
      acc = Math.max(acc, ObjectKeys(ofp.orgTeamTagIds ?? {}).length);
      return acc;
    }, 0);

    // Narrow down the matching packages to only the ones with the most matching tags
    const orgRegistrationPackagesThatTeamMatchesTheMostOrgTeamTagIds =
      orgRegistrationPackagesThatTeamMatchesAllOrgTeamTagIdsAndBirthYear.filter(
        ofp => ObjectKeys(ofp.orgTeamTagIds ?? {}).length === maxNumberOfMatchingTags
      );

    // If we don't have any matching packages, continue to the next season
    if (!orgRegistrationPackagesThatTeamMatchesTheMostOrgTeamTagIds.length) {
      results[orgSeasonId] = [];
    }

    // If we have exactly 1 matching package, that is the winner so continue
    if (orgRegistrationPackagesThatTeamMatchesTheMostOrgTeamTagIds.length === 1) {
      results[orgSeasonId] = [orgRegistrationPackagesThatTeamMatchesTheMostOrgTeamTagIds[0]];
    }

    // We want to eliminate packages that don't specify birth year if there exists
    // another package with the exact same org team tag ids that does specify birth year
    const orgRegistrationPackagesAfterRemovingPackagesThatEquallyMatchOtherPackagesButArentAsSpecificOnBirthYear =
      orgRegistrationPackagesThatTeamMatchesTheMostOrgTeamTagIds.filter(ofp => {
        const shouldEliminate =
          !ofp.birthYears &&
          !!orgRegistrationPackagesThatTeamMatchesTheMostOrgTeamTagIds.find(
            otherOfp =>
              otherOfp.id !== ofp.id &&
              !ObjectKeys(otherOfp.orgTeamTagIds ?? {}).find(ottId => !ofp.orgTeamTagIds?.[ottId]) &&
              p.team.birthYear &&
              !!otherOfp.birthYears?.[p.team.birthYear]
          );
        return !shouldEliminate;
      });

    // We have found multiple registration packages that have the same specificity of team tags
    // Cost will be the tiebreaker
    const highestCost =
      orgRegistrationPackagesAfterRemovingPackagesThatEquallyMatchOtherPackagesButArentAsSpecificOnBirthYear.reduce(
        (acc, ofp) => {
          acc = Math.max(acc, ofp.amountCents);
          return acc;
        },
        0
      );

    // Narrow down by tags that match the highest cost
    const finalOrgRegistrationPackagesThatMatchTheHighestCost =
      orgRegistrationPackagesAfterRemovingPackagesThatEquallyMatchOtherPackagesButArentAsSpecificOnBirthYear.filter(
        ofp => ofp.amountCents === highestCost
      );

    // Return all remaining packages
    results[orgSeasonId] = finalOrgRegistrationPackagesThatMatchTheHighestCost;
  }

  return results;
}

export function computeRollOverPackageInfo(pkg: OrgRegistrationPackage): {
  name: string;
  birthYears: OrgRegistrationPackage["birthYears"];
} {
  const newBirthYears = pkg.birthYears
    ? Object.keys(pkg.birthYears ?? {}).reduce((acc, val) => {
        acc[String(parseInt(val) + 1)] = true;
        return acc;
      }, {} as Record<string, true>)
    : undefined;
  const newName = pkg.name.replace(/\b(\d\d\d\d|\d\d)\b/gi, val => {
    const newNum = (Number(val.match(/\d+/)![0]) + 1).toString().padStart(val.length, "0");
    return val.replace(/\d+/, newNum);
  });

  return {
    birthYears: newBirthYears,
    name: newName
  };
}

export function determineRequiredRegistrationPackageForPlayerBundle(p: {
  registrationPackages: OrgRegistrationPackage[];
  teamSettings: TeamSettings[];
  orgSeasonId: OrgSeasonId;
}) {
  const assignedPackageIds = _.compact(p.teamSettings.map(ts => ts.registrationPackageIdBySeasonId?.[p.orgSeasonId]));
  const assignedPackages = p.registrationPackages.filter(orp => assignedPackageIds.includes(orp.id));

  const mostExpensivePackage = assignedPackages.length ? _.orderBy(assignedPackages, a => a.amountCents, "desc")[0] : undefined;
  return mostExpensivePackage;
}
