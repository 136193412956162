import { AccountPrivate, Org, sandboxTeamId, Team, Team__StaffTypes, FeatureToggles, exampleTeamId } from "@ollie-sports/models";
import { getServerHelpers, getUniversalHelpers } from "../helpers";

export enum PremiumGateOverrides {
  alwaysAllowHeadCoaches = "alwaysAllowHeadCoaches",
  alwaysAllowOrgAdmins = "alwaysAllowOrgAdmins",
  alwaysAllowStatTaker = "alwaysAllowStatTaker"
}

type Props = {
  accountId: string;
  team?: Team;
  org?: Org | null;
  accountPrivate: AccountPrivate | null;
  overrides?: PremiumGateOverrides[];
};

export function hasPremium(p: Props): boolean {
  // Always allow sandbox  team
  if (p.team?.id === sandboxTeamId) {
    return true;
  }

  if (p.team?.id === exampleTeamId) {
    return true;
  }

  // Assume if they have any linked license that they are good. This field is managed by the backend system
  // This can originate from various places in-app purchase, part of a family plan, a trial, a gift license
  if (p.accountPrivate?.linkedLicenseId) {
    return true;
  }

  // Assume if the team has a linked license that they are good. This field is managed by the backend system
  // The team or club has a license
  if (p.team?.linkedLicenseId) {
    return true;
  }

  if (p.overrides) {
    if (
      p.overrides.includes(PremiumGateOverrides.alwaysAllowHeadCoaches) &&
      p.team?.accounts?.[p.accountId]?.staffTitle === Team__StaffTypes.headCoach
    ) {
      return true;
    }
    if (p.overrides.includes(PremiumGateOverrides.alwaysAllowOrgAdmins) && p.org && p.org.accounts[p.accountId]) {
      return true;
    }
  }

  return false;
}

interface BulkHasPremiumReturn {
  accountIdsWithPremium: string[];
  accountIdsWithoutPremium: string[];
}

export async function bulkFetchAccountIdsHavePremium(p: {
  teamId: string;
  accountIds: string[];
  overrides: PremiumGateOverrides[];
}): Promise<BulkHasPremiumReturn> {
  // SERVER_ONLY_TOGGLE
  const { appOllieFirestoreV2: h, appOllieRtdb } = getServerHelpers();
  const team = await h.Team.getDoc(p.teamId);

  let pendingResult: BulkHasPremiumReturn = { accountIdsWithPremium: [], accountIdsWithoutPremium: [] };

  if (!team) {
    throw new Error("Unable to find team when running bulkFetchAccountIdsHavePremium");
  }

  // Shortcut individual checks if the team has a license
  if (team.linkedLicenseId) {
    pendingResult.accountIdsWithPremium = p.accountIds;
    return pendingResult;
  }

  let org: Org | null;
  if (team.orgId) {
    org = await h.Org.getDoc(team.orgId);
  }

  await Promise.all(
    p.accountIds.map(async id => {
      const accountPrivate = await h.AccountPrivate.getDoc(id);
      let hp = await hasPremium({
        accountId: id,
        team: team,
        org: org,
        overrides: p.overrides,
        accountPrivate: accountPrivate
      });
      if (hp) {
        pendingResult.accountIdsWithPremium.push(id);
      } else {
        pendingResult.accountIdsWithoutPremium.push(id);
      }
    })
  );

  return pendingResult;
  // SERVER_ONLY_TOGGLE
}

// i18n certified - complete
