import React, { ReactNode } from "react";

export function extractTextFromReactNode(node: ReactNode) {
    // If the node is a string, return it as the text content
    if (typeof node === "string") {
      return node;
    }

    // If the node is a number, convert it to a string and return it
    if (typeof node === "number") {
      return node.toString();
    }

    // If the node is an array, recursively extract text from each element
    if (Array.isArray(node)) {
      return node.map(extractTextFromReactNode).join("");
    }

    // If the node is a React element, recursively extract text from its children
    if (React.isValidElement(node)) {
      return extractTextFromReactNode(node.props.children);
    }

    // If none of the above, return an empty string
    return "";
  }
