import { getServerHelpers } from "../helpers";

const algorithm = "aes-256-ctr";

export function encryptString(text: string) {
  // SERVER_ONLY_TOGGLE
  const crypto = getServerHelpers().injectedServerLibraries.crypto;
  const iv = crypto.randomBytes(16);
  const secretKey = getServerHelpers().serverConfig.stringEncryptionKey;
  const cipher = crypto.createCipheriv(algorithm, secretKey, iv);
  const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);
  return JSON.stringify({
    iv: iv.toString("hex"),
    content: encrypted.toString("hex")
  });
  // SERVER_ONLY_TOGGLE
}

export function decryptString(text: string) {
  // SERVER_ONLY_TOGGLE
  const hash = JSON.parse(text);
  const crypto = getServerHelpers().injectedServerLibraries.crypto;
  const secretKey = getServerHelpers().serverConfig.stringEncryptionKey;
  const decipher = crypto.createDecipheriv(algorithm, secretKey, Buffer.from(hash.iv, "hex"));
  const decrpyted = Buffer.concat([decipher.update(Buffer.from(hash.content, "hex")), decipher.final()]);
  return decrpyted.toString();
  // SERVER_ONLY_TOGGLE
}

export function generateRandomHexString() {
  // SERVER_ONLY_TOGGLE
  const crypto = getServerHelpers().injectedServerLibraries.crypto;
  return crypto.randomBytes(32).toString("hex");
  // SERVER_ONLY_TOGGLE
}
