import { translate as origTranslate, Message } from "@ollie-sports/i18n";

//A convenience function for use on the server that REQUIRES a serverLocale
export function translateServer(
  p: {
    defaultMessage: Message["defaultMessage"];
    serverLocale: string;
    description?: Message["description"];
  },
  vars?: Record<string, string | number>
) {
  return origTranslate(p, vars);
}
