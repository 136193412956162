import { OrgRegistrationQuestion, OrgRegistrationQuestionType } from "@ollie-sports/models";

export function getOrgRegistrationQuestionMainBodyText(orgRegistrationQuestion: Partial<OrgRegistrationQuestion>) {
  switch (orgRegistrationQuestion.type) {
    case OrgRegistrationQuestionType.additionalStep:
      return orgRegistrationQuestion.descriptionMD ?? "";
    case OrgRegistrationQuestionType.explainer:
    case OrgRegistrationQuestionType.legalDoc:
      return orgRegistrationQuestion.textMD ?? "";
    case OrgRegistrationQuestionType.radio:
    case OrgRegistrationQuestionType.checkbox:
    case OrgRegistrationQuestionType.freeResponse:
      return orgRegistrationQuestion.questionMD ?? "";
    default:
      return "";
  }
}
