//TODO: USE fuzzyTextSearch.ts (file next to this) INSTEAD OF THIS. IT'S ALMOST CERTAINLY BETTER.

import { useEffect, useRef, useState } from "react";
import { createSimpleWebWorker } from "./createSimpleWebWorker";

type FuzzyMatch = {
  score: number;
  textMatches: bigint;
  secondaryTextMatches: bigint;
};

type BatchFuzzyMatcher<T> = (p: {
  searchTerm: string;
  arrayToSearch: { primaryText: string; secondaryText?: string; context?: T }[];
}) => { match: FuzzyMatch; context: T }[];

export function getFuzzyMatches<T>(p: Parameters<BatchFuzzyMatcher<T>>[0]): ReturnType<BatchFuzzyMatcher<T>> {
  const baseSearchTermArr = p.searchTerm
    .toLowerCase()
    .split(" ")
    .map(a => a.trim())
    .filter(a => !!a);

  const searchTermArr = baseSearchTermArr.slice();

  baseSearchTermArr.forEach(term => {
    if (term.length < 4) {
      //Small substrings are too likely to show up in random places. So don't do these little fuzzy things
      return;
    }
    //Add single character deletions to searchTermArr
    for (let i = 0; i < term.length; i++) {
      searchTermArr.push(term.slice(0, i) + term.slice(i + 1));
    }

    //Add single character tranpositions to searchTermArr
    for (let i = 0; i < term.length - 1; i++) {
      searchTermArr.push(term.slice(0, i) + term[i + 1] + term[i] + term.slice(i + 2));
    }

    //Add extra character in every search slot. Not ideal... We need to switch to fuzzyTextSearch.ts eventually.
    //It's a better algorithm than this crap. But it would have taken me longer
    const alphabet = "abcdefghijklmnopqrstuvwxyz";
    for (let i = 0; i < term.length - 1; i++) {
      for (let j = 0; j < alphabet.length; j++) {
        searchTermArr.push(term.slice(0, i) + alphabet[j] + term.slice(i));
      }
    }
  });

  baseSearchTermArr.forEach(term => {});

  function countNumMatches(str: string, searchTerm: string) {
    let n = 0,
      pos = 0,
      step = searchTerm.length;

    while (true) {
      pos = str.indexOf(searchTerm, pos);
      if (pos >= 0) {
        ++n;
        pos += step;
      } else break;
    }
    return n;
  }

  function toBinaryMatches(text: string, strMatches: string[]) {
    const binaryStr = text.split("").map(() => "0");
    strMatches.forEach(term => {
      binaryStr.forEach((char, i) => {
        if (text.substr(i, term.length) === term) {
          for (let j = 0; j < term.length; j++) {
            binaryStr[j + i] = "1";
          }
        }
      });
    });
    return BigInt("0b" + binaryStr.reverse().join(""));
  }

  function searchMultiple(primaryTextLower: string, secondaryTextLower?: string): FuzzyMatch {
    const match = {
      score: 0,
      textMatches: BigInt(0),
      secondaryTextMatches: BigInt(0)
    };
    const matchingStrings = { primary: [] as string[], secondary: [] as string[] };
    searchTermArr.forEach(thisTerm => {
      let hasPrimaryMatch = false;
      let hasSecondaryMatch = false;
      const numPrimaryMatches = countNumMatches(primaryTextLower, thisTerm);
      if (numPrimaryMatches) {
        hasPrimaryMatch = true;
        matchingStrings.primary.push(thisTerm);
      }

      const numSecondaryMatches = secondaryTextLower ? countNumMatches(secondaryTextLower, thisTerm) : 0;
      if (numSecondaryMatches) {
        hasSecondaryMatch = true;
        matchingStrings.secondary.push(thisTerm);
      }

      let addlScore = 0;
      if (hasPrimaryMatch && hasSecondaryMatch) {
        addlScore += thisTerm.length * 1.1;
      } else if (hasPrimaryMatch && !hasSecondaryMatch) {
        addlScore += thisTerm.length;
      } else if (!hasPrimaryMatch && hasSecondaryMatch) {
        addlScore += thisTerm.length * 0.9;
      }

      if (addlScore) {
        if (numPrimaryMatches) {
          addlScore = addlScore * (1 + 0.05 * numPrimaryMatches);
        } else if (numSecondaryMatches) {
          addlScore = addlScore * (1 + 0.025 * numSecondaryMatches);
        }
      }

      match.score += addlScore;
    });
    if (matchingStrings.primary.length) {
      match.textMatches = toBinaryMatches(primaryTextLower, matchingStrings.primary);
    }

    if (secondaryTextLower && matchingStrings.secondary.length) {
      match.secondaryTextMatches = toBinaryMatches(secondaryTextLower, matchingStrings.secondary);
    }

    return match;
  }

  return p.arrayToSearch.map(v => {
    const match = searchMultiple(v.primaryText.toLowerCase(), v.secondaryText?.toLowerCase());

    return {
      match,
      context: v.context as T
    };
  });
}

const worker = createSimpleWebWorker(getFuzzyMatches);
export async function getFuzzyMatchesAsync<T>(p: Parameters<BatchFuzzyMatcher<T>>[0]): Promise<ReturnType<BatchFuzzyMatcher<T>>> {
  const start = Date.now();
  const resultsProm = worker({
    //Strip out the context since it very well may not be serializable and is added expense talking across the wire
    arrayToSearch: p.arrayToSearch.map(({ context, ...rest }) => ({
      ...rest
    })),
    searchTerm: p.searchTerm
  }) as Promise<ReturnType<BatchFuzzyMatcher<T>>>;

  const results = await resultsProm;

  console.log("time", Date.now() - start);

  return results.map((r, i) => ({
    context: p.arrayToSearch[i].context as T, //add back context
    match: r.match
  }));
}

export function useArrayFilteredBySearch<T>(p: { searchTerm: string; dataArray: T[]; primaryTextAccessor: (a: T) => string }) {
  const [filteredArray, setFilteredArray] = useState<{ data: T; match?: FuzzyMatch }[]>(p.dataArray.map(a => ({ data: a })));

  const counter = useRef(0);

  useEffect(() => {
    counter.current++;
    const currCounter = counter.current;
    if (p.searchTerm) {
      getFuzzyMatchesAsync({
        arrayToSearch: p.dataArray.map(a => ({ primaryText: p.primaryTextAccessor(a), context: a })),
        searchTerm: p.searchTerm
      }).then(matches => {
        if (currCounter !== counter.current) {
          return;
        }
        const newArray = matches
          .filter(m => m.match.score > 0)
          .sort((a, b) => b.match.score - a.match.score)
          .map(m => ({
            data: m.context,
            match: m.match
          }));

        setFilteredArray(newArray);
      });
    } else {
      setFilteredArray(p.dataArray.map(a => ({ data: a })));
    }
  }, [p.searchTerm, p.dataArray.map(a => p.primaryTextAccessor(a)).join("")]);

  return filteredArray;
}
