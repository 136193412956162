//Cut string off only at word ends...
export function cleanlyCutString(str: string, maxLength: number) {
  const split = str.split(" ");

  let newStr = split.shift() || "";
  for (let i = 0; i < split.length; i++) {
    const thisPart = split[i];
    if (newStr.length + thisPart.length + 1 < maxLength) {
      newStr += " " + thisPart;
    } else {
      newStr = newStr.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]$/, "") + "...";
      break;
    }
  }

  return newStr;
}
