import { dateFormatters, translate } from "@ollie-sports/i18n";
import { Account, CalendarEntry, CALENDAR_ENTRY_TYPES, PlayerId, PrettyPlayer, Team } from "@ollie-sports/models";
import _ from "lodash";
import moment from "moment";
import { formatPercent } from "./number-strings";
import { ObjectValues } from "./object-keys";

export function getAttendanceReportExportString(p: {
  calendarEntries: CalendarEntry[];
  type: "coach" | "player";
  playerOrCoachInfo: { id: string; name: string }[];
  team: Team;
  squad?: "a" | "b" | "c";
  eventType?: CALENDAR_ENTRY_TYPES;
  startDateMS: number;
  endDateMS: number;
  locale: string;
}) {
  const sortedPlayerOrCoachInfo = _.orderBy(p.playerOrCoachInfo, ["name"]);
  const numPlayers = sortedPlayerOrCoachInfo.length;
  const showOpponent = p.eventType !== CALENDAR_ENTRY_TYPES.practice && p.eventType !== CALENDAR_ENTRY_TYPES.other;
  const showSquad = !!p.team.enabledFeatures?.squads && !!p.team.squads;

  let csvString = `${translate(
    {
      defaultMessage: `{
    titleStr
  } - {startDateStr} to {endDateStr}`,
      serverLocale: p.locale
    },
    {
      titleStr:
        p.type === "player"
          ? translate({ defaultMessage: "Player Attendance Report", serverLocale: p.locale })
          : translate({ defaultMessage: "Coach Attendance Report", serverLocale: p.locale }),
      startDateStr: dateFormatters.mm_dd_yyyy(p.startDateMS, p.locale),
      endDateStr: dateFormatters.mm_dd_yyyy(p.endDateMS, p.locale)
    }
  )}\n\n${translate.common(p.locale).Date},${translate.common(p.locale).Date},${
    showSquad ? `${translate.common(p.locale).Squad},` : ""
  }${showOpponent ? `${translate.common(p.locale).Opponent},` : ""}${sortedPlayerOrCoachInfo
    .map(info => `${info.name}`)
    .join(",")},,${translate.common(p.locale).Attended} (${translate({
    defaultMessage: "Includes Tardy",
    serverLocale: p.locale
  })}),${translate.common(p.locale).MissedEvent},${translate.common(p.locale).Unknown},${translate.common(p.locale).Tardy},,${
    translate.common(p.locale).Attended
  } %,${translate.common(p.locale).MissedEvent} %,${translate.common(p.locale).Unknown} %,${
    translate.common(p.locale).Tardy
  } %\n`;

  const playerAttendanceValueTotals: Record<PlayerId, { A: number; T: number; M: number; U: number }> = {};

  p.calendarEntries.forEach(calendarEntry => {
    const attendanceValueById = sortedPlayerOrCoachInfo.reduce((acc, info) => {
      const attendanceToUse = p.type === "player" ? calendarEntry.attendance : calendarEntry.coachesAttendace;
      const attended = !!attendanceToUse?.[info.id]?.coming;
      const missed = !!attendanceToUse?.[info.id] && !attended;
      const tardy = !!attendanceToUse?.[info.id]?.isTardy;
      const attendanceValue = missed ? "M" : tardy ? "T" : attended ? "A" : "U";
      acc[info.id] = attendanceValue;
      return acc;
    }, {} as Record<string, "A" | "T" | "M" | "U">);

    const attendanceTotalValues = _.countBy(ObjectValues(attendanceValueById));

    const numAttended = (attendanceTotalValues["A"] ?? 0) + (attendanceTotalValues["T"] ?? 0);
    const attendedPercent = numPlayers > 0 ? numAttended / numPlayers : 0;
    const numMissed = attendanceTotalValues["M"] ?? 0;
    const missedPercent = numPlayers > 0 ? numMissed / numPlayers : 0;
    const numUnknown = attendanceTotalValues["U"] ?? 0;
    const unknownPercent = numPlayers > 0 ? numUnknown / numPlayers : 0;
    const numTardy = attendanceTotalValues["T"] ?? 0;
    const tardyPercent = numPlayers > 0 ? numTardy / numPlayers : 0;

    csvString += `${dateFormatters.mm_dd_yyyy(calendarEntry.startDateTime, p.locale)},${_.upperFirst(
      calendarEntry.calendarEntryType
    )},${
      showSquad
        ? `${
            calendarEntry.squad
              ? p.team.squads?.[calendarEntry.squad]?.name ||
                translate({ defaultMessage: "Unknown Squad", serverLocale: p.locale })
              : translate.common(p.locale).All
          },`
        : ""
    }${
      showOpponent
        ? `${
            calendarEntry.calendarEntryType === CALENDAR_ENTRY_TYPES.game ||
            calendarEntry.calendarEntryType === CALENDAR_ENTRY_TYPES.scrimmage
              ? `${calendarEntry.opponentName}`
              : ""
          },`
        : ""
    }${sortedPlayerOrCoachInfo
      .map(info => {
        const attendanceValue = attendanceValueById[info.id] ?? "U";
        const stringValue =
          attendanceValue === "A"
            ? translate.common(p.locale).AttendedOneLetter
            : attendanceValue === "M"
            ? translate.common(p.locale).MissedEventOneLetter
            : attendanceValue === "T"
            ? translate.common(p.locale).TardyOneLetter
            : translate.common(p.locale).UnknownOneLetter;
        if (!playerAttendanceValueTotals[info.id]) {
          playerAttendanceValueTotals[info.id] = { A: 0, M: 0, T: 0, U: 0 };
        }

        playerAttendanceValueTotals[info.id][attendanceValue] = playerAttendanceValueTotals[info.id][attendanceValue] + 1;
        return stringValue;
      })
      .join(",")},,${numAttended},${numMissed},${numUnknown},${numTardy},,${formatPercent(attendedPercent, {
      numDecimals: 0
    })},${formatPercent(missedPercent, {
      numDecimals: 0
    })},${formatPercent(unknownPercent, {
      numDecimals: 0
    })},${formatPercent(tardyPercent, { numDecimals: 0 })}\n`;
  });

  const emptyColumnsBeforeTotalsData = `,${showSquad ? "," : ""}${showOpponent ? "," : ""}`;

  csvString += `${emptyColumnsBeforeTotalsData}\n`;
  csvString += `${emptyColumnsBeforeTotalsData}${translate.common(p.locale).Attended} (${translate({
    defaultMessage: "Includes Tardy",
    serverLocale: p.locale
  })}),${sortedPlayerOrCoachInfo
    .map(info => {
      return `${(playerAttendanceValueTotals[info.id]?.A ?? 0) + (playerAttendanceValueTotals[info.id]?.T ?? 0)}`;
    })
    .join(",")}\n`;
  csvString += `${emptyColumnsBeforeTotalsData}${translate.common(p.locale).MissedEvent},${sortedPlayerOrCoachInfo
    .map(info => {
      return `${playerAttendanceValueTotals[info.id]?.M ?? 0}`;
    })
    .join(",")}\n`;
  csvString += `${emptyColumnsBeforeTotalsData}${translate.common(p.locale).Unknown},${sortedPlayerOrCoachInfo
    .map(info => {
      return `${playerAttendanceValueTotals[info.id]?.U ?? 0}`;
    })
    .join(",")}\n`;
  csvString += `${emptyColumnsBeforeTotalsData}${translate.common(p.locale).Tardy},${sortedPlayerOrCoachInfo
    .map(info => {
      return `${playerAttendanceValueTotals[info.id]?.T ?? 0}`;
    })
    .join(",")}\n`;
  csvString += "\n";
  csvString += `${emptyColumnsBeforeTotalsData}${translate.common(p.locale).Attended} %,${sortedPlayerOrCoachInfo
    .map(info => {
      const numAttended = (playerAttendanceValueTotals[info.id]?.A ?? 0) + (playerAttendanceValueTotals[info.id]?.T ?? 0);
      return `${formatPercent(p.calendarEntries.length > 0 ? numAttended / p.calendarEntries.length : 0, { numDecimals: 0 })}`;
    })
    .join(",")}\n`;
  csvString += `${emptyColumnsBeforeTotalsData}${translate.common(p.locale).MissedEvent} %,${sortedPlayerOrCoachInfo
    .map(info => {
      return `${formatPercent(
        p.calendarEntries.length > 0 ? (playerAttendanceValueTotals[info.id]?.M ?? 0) / p.calendarEntries.length : 0,
        { numDecimals: 0 }
      )}`;
    })
    .join(",")}\n`;
  csvString += `${emptyColumnsBeforeTotalsData}${translate.common(p.locale).Unknown} %,${sortedPlayerOrCoachInfo
    .map(info => {
      return `${formatPercent(
        p.calendarEntries.length > 0 ? (playerAttendanceValueTotals[info.id]?.U ?? 0) / p.calendarEntries.length : 0,
        { numDecimals: 0 }
      )}`;
    })
    .join(",")}\n`;
  csvString += `${emptyColumnsBeforeTotalsData}${translate.common(p.locale).Tardy} %,${sortedPlayerOrCoachInfo
    .map(info => {
      return `${formatPercent(
        p.calendarEntries.length > 0 ? (playerAttendanceValueTotals[info.id]?.T ?? 0) / p.calendarEntries.length : 0,
        { numDecimals: 0 }
      )}`;
    })
    .join(",")}\n`;

  csvString += `\n${emptyColumnsBeforeTotalsData}${translate({
    defaultMessage: "Key",
    description: "key as in a legend or something that explains what symbols in a table mean",
    serverLocale: p.locale
  })}\n${emptyColumnsBeforeTotalsData}${translate.common(p.locale).AttendedOneLetter} = ${
    translate.common(p.locale).Attended
  }\n${emptyColumnsBeforeTotalsData}${translate.common(p.locale).MissedEventOneLetter} = ${
    translate.common(p.locale).MissedEvent
  }\n${emptyColumnsBeforeTotalsData}${translate.common(p.locale).TardyOneLetter} = ${
    translate.common(p.locale).Tardy
  }\n${emptyColumnsBeforeTotalsData}${translate.common(p.locale).UnknownOneLetter} = ${translate.common(p.locale).Unknown}`;
  return csvString;
}

// i18n certified - complete
