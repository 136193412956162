import { translate, translateNumberToAbbreviatedOrdinal } from "@ollie-sports/i18n";

export function msToMinutesString(ms: number, minutesOnly?: boolean) {
  if (!ms) {
    return minutesOnly ? "0" : "0:00";
  }
  const totalSeconds = Math.round(ms / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  const displaySeconds = seconds < 10 ? "0" + seconds : seconds;

  return `${minutes}${minutesOnly ? "" : `:${displaySeconds}`}`;
}

export function secondsToMinutesString(seconds: number) {
  const minutes = Math.floor(seconds / 60);
  const extraSeconds = seconds % 60;
  const displaySeconds = extraSeconds < 10 ? "0" + extraSeconds : extraSeconds;

  return `${minutes}:${displaySeconds}`;
}

export function msToOrdinalMinuteString(ms: number, locale: string) {
  const totalSeconds = Math.round(ms / 1000);
  const minutes = Math.floor(totalSeconds / 60);

  return translateNumberToAbbreviatedOrdinal(minutes);
}

/* converts hh:mm formatted time to minutes integer */
export function stringToMinutes(timeString: string) {
  const [hours, mins] = timeString.split(":");
  return parseInt(hours) * 60 + parseInt(mins);
}

// converts mm:ss formatted time to minutes integer for soccer time. 12:35 is converted to 13
export function minutesAndSecondsStringToMinutes(timeString: string) {
  const [mins, secs] = timeString.split(":");
  return timeString === "0:00" ? 0 : parseInt(mins) + 1;
}

export function minutesToString(minutes: number) {
  return `${Math.floor(minutes / 60)}:${Math.floor(minutes % 60)}`;
}

export function getTimeAndDateStringFromMS(datetime: number) {
  const createdAtDate = new Date(datetime);
  let dateString = "";
  let hour = createdAtDate.getHours();
  const minute = createdAtDate.getMinutes();
  const pm = hour > 11;
  hour = hour > 12 ? hour - 12 : hour;
  dateString = `${hour}:${minute < 10 ? "0" + minute : minute} ${pm ? "pm" : "am"}`;
  const year = createdAtDate.getFullYear();
  const month = createdAtDate.getMonth() + 1;
  const date = createdAtDate.getDate();
  if (date !== new Date().getDate() || month !== new Date().getMonth() + 1 || year !== new Date().getFullYear()) {
    dateString = `${month < 10 ? "0" + month : month}/${date < 10 ? "0" + date : date}/${year} ` + dateString;
  }
  return dateString;
}

export function getTimeStringFromMS(datetime: number, locale: string) {
  const createdAtDate = new Date(datetime);
  let dateString = "";
  let hour = createdAtDate.getHours();
  const minute = createdAtDate.getMinutes();
  const pm = hour > 11;
  hour = hour > 12 ? hour - 12 : hour;
  dateString = `${hour}:${minute < 10 ? "0" + minute : minute} ${
    pm
      ? translate({ defaultMessage: "pm", description: "for times, as in 5:00 pm", serverLocale: locale })
      : translate({ defaultMessage: "am", description: "for times, as in 7:00 am", serverLocale: locale })
  }`;
  return dateString;
}

// i18n certified - complete
