import { PrettyPlayerBundle, Team, TeamFeatures, TEAM_SPORT, TEAM_TYPES } from "@ollie-sports/models";
import _ from "lodash";
import { ObjectKeys, ObjectValues } from ".";
import { common__extractTeamIdAndSquadFromTeamIdWithSquad } from "../api/common.api";

export function getLastMajorGameUpdateForPlayerBundleArraySortedForTeamIdsWithSquad(p: {
  prettyPlayerBundle?: PrettyPlayerBundle;
  teams: Team[];
}) {
  const linkedPlayerObjects = Object.values(p.prettyPlayerBundle?.playerBundle.derived.linkedPlayers ?? {});

  const teamIds = _.uniq(linkedPlayerObjects.map(a => a.teamId));

  return p.teams
    ?.filter(t => teamIds.includes(t.id))
    .map(t => t.lastTimeMajorGameUpdateWasMadeMS ?? 0)
    .sort();
}

export function getDefaultEnabledFeaturesForTeam(p: { teamSport: TEAM_SPORT; teamType: TEAM_TYPES }): {
  [feature in TeamFeatures]?: true;
} {
  const enabledFeatures: {
    [feature in TeamFeatures]?: true;
  } = {};
  switch (p.teamSport) {
    case TEAM_SPORT.soccer:
      enabledFeatures[TeamFeatures.stats] = true;
      enabledFeatures[TeamFeatures.mvps] = true;
      enabledFeatures[TeamFeatures.awards] = true;
      if (p.teamType === TEAM_TYPES.highschool || p.teamType === TEAM_TYPES.college || p.teamType === TEAM_TYPES.adult) {
        enabledFeatures[TeamFeatures.viewIndividualStatsForEveryone] = true;
      }
      break;
    case TEAM_SPORT.americanFootball:
    case TEAM_SPORT.baseball:
    case TEAM_SPORT.basketball:
    case TEAM_SPORT.hockey:
    case TEAM_SPORT.lacrosse:
    case TEAM_SPORT.softball:
    case TEAM_SPORT.volleyball:
    case TEAM_SPORT.waterPolo:
  }
  return enabledFeatures;
}
// i18n certified - complete
