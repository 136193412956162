import { produce } from "immer";
import { usePersistentState, useSessionPersistentState } from "./usePersistentState";

type ImmutableStateSetter<T> = (partialOrChangeFn: Partial<T> | ((a: T) => void)) => void;
export function usePersistentImmutableState<T extends Record<string, any>>(
  initValue: T,
  persistenceKey: string,
  beforeLoad?: (a: T) => T //Use null if NA.
): [T, ImmutableStateSetter<T>, { hasLoaded: boolean; revertToInitial: () => void }] {
  const [val, setVal, extra] = usePersistentState({ initialValue: initValue, key: persistenceKey, beforeLoad });

  return [
    val,
    partialOrChangeFn => {
      //@ts-ignore
      setVal(currVal => {
        if (typeof partialOrChangeFn === "function") {
          return produce(currVal, partialOrChangeFn);
        } else {
          return { ...currVal, ...partialOrChangeFn };
        }
      });
    },
    {
      hasLoaded: extra.hasLoaded,
      revertToInitial: extra.revertToInitialValue
    }
  ];
}

export function usePersistentSessionImmutableState<T extends Record<string, any>>(
  initValue: T,
  persistenceKey: string
): [T, ImmutableStateSetter<T>] {
  const [val, setVal] = useSessionPersistentState({ initialValue: initValue, key: persistenceKey });

  return [
    val,
    partialOrChangeFn => {
      //@ts-ignore
      setVal(currVal => {
        if (typeof partialOrChangeFn === "function") {
          return produce(currVal, partialOrChangeFn);
        } else {
          return { ...currVal, ...partialOrChangeFn };
        }
      });
    }
  ];
}
