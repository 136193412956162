export const fileToBase64 = async (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(String(reader.result));
    reader.onerror = e => reject(e);
  });

export async function downscaleImage(base64: string, newWidth: number, forceOneToOneAspectRatio?: boolean) {
  return new Promise<{ blob: Blob | null; height: number; width: number }>((resolve, reject) => {
    const image = new Image();

    image.onload = function () {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (forceOneToOneAspectRatio) {
        const size = Math.min(image.width, image.height);
        const offsetX = (image.width - size) / 2;
        const offsetY = (image.height - size) / 2;
        canvas.width = newWidth;
        canvas.height = newWidth;
        ctx?.drawImage(image, offsetX, offsetY, size, size, 0, 0, newWidth, newWidth);
      } else {
        canvas.width = Math.min(newWidth, image.width);
        canvas.height = canvas.width * (image.height / image.width);
        ctx?.drawImage(image, 0, 0, canvas.width, canvas.height);
      }
      canvas.toBlob(
        blob => {
          resolve({ blob, width: canvas.width, height: canvas.height });
        },
        "image/png",
        0.7
      );
    };
    image.src = base64;
  });
}

export async function getBlob(base64: string) {
  return new Promise<{ blob: Blob | null; height: number; width: number }>((resolve, reject) => {
    const image = new Image();

    image.onload = function () {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = image.width;
      canvas.height = image.height;
      ctx?.drawImage(image, 0, 0, canvas.width, canvas.height);

      canvas.toBlob(
        blob => {
          resolve({ blob, width: canvas.width, height: canvas.height });
        },
        "image/png",
        0.7
      );
    };
    image.src = base64;
  });
}

export function downloadStringAsFile(filename: string, data: string) {
  const blob = new Blob([data], { type: "text/csv" });

  // Create a URL for the Blob
  const url = URL.createObjectURL(blob);

  // Create a temporary anchor element
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;

  // Programmatically click the anchor to trigger the download
  link.click();

  // Revoke the object URL to free up memory
  URL.revokeObjectURL(url);
}
