//Returns a function that returns the value of the provided callback
export function createLazyGetter<T>(fn: () => T) {
  let val: T;
  return () => {
    if (!val) {
      val = fn();
    }

    return val;
  };
}
