import { COLORS, COLORS_BASE, COLOR_OPACITY_REDUCE } from "../theme/COLORS";

type TagTypes =
  | "rawEmail"
  | "account"
  | "orgTag"
  | "orgAdminsTag"
  | "teamTag"
  | "teamTagCollapseGroup"
  | "arbitraryPlayerBundleGrouping"
  | "arbitraryAccountOrPlayerBundleGrouping";

const map: Record<TagTypes, COLORS_BASE> = {
  rawEmail: COLORS.green,
  account: COLORS.red,
  orgTag: COLORS.purple,
  orgAdminsTag: COLORS.gold,
  teamTag: COLORS.blue,
  teamTagCollapseGroup: COLORS.blue,
  arbitraryPlayerBundleGrouping: COLORS.blue,
  arbitraryAccountOrPlayerBundleGrouping: COLORS.blue
};
export function getTagColor(type: TagTypes, lighten?: true) {
  return lighten ? COLOR_OPACITY_REDUCE(map[type], 0.1) : map[type];
}
