import { formatMoneyCentsToDollarCentPrettyString, isParentOrgInvoice } from "@ollie-sports/core";
import { translate } from "@ollie-sports/i18n";
import { OrgInvoice, OrgPaymentInvoiceCreditApplicationMethod, OrgPaymentType } from "@ollie-sports/models";
import { useState } from "react";
import { View } from "react-native-web";
import { Form, PrettyCoolTextInputWithLabel } from "../components/Form";
import { StyledText } from "../components/StyledText";
import { FullScreenModal } from "../components/modals/getFullscreenModal";
import { getCurrentUserAccountId } from "../hooks/commonDataUtils";
import { getBifrost } from "../services/bifrost.service";
import { openErrorToast } from "./openErrorToast";
import { openModal } from "../components/modals/imperativeModal";

interface ForgivePaymentModalProps {
  orgInvoice: OrgInvoice;
  remainingAmount: number;
  onComplete: () => Promise<void>;
  forgiveChildren?: boolean;
}

export function openPaymentForgivenessModal(p: ForgivePaymentModalProps) {
  return new Promise<void>(res => {
    const modal = openModal({
      body: (
        <ForgivePaymentModal
          {...p}
          onRequestDismiss={() => {
            modal.close();
            res();
          }}
        />
      )
    });
  });
}

function ForgivePaymentModal(
  p: ForgivePaymentModalProps & {
    onRequestDismiss: () => void;
  }
) {
  const [note, setNote] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Form
      children={isFormValid => {
        return (
          <FullScreenModal
            title={translate({ defaultMessage: "Forgive Payment?" })}
            bottomButton={{
              title: translate.common.Apply,
              enabled: isFormValid && !isLoading,

              onPress: async () => {
                setIsLoading(true);
                try {
                  if (!note) {
                    throw new Error("");
                  }
                  await getBifrost().orgPayment__server__addOrgPaymentInvoiceCredits.fetchServer(
                    isParentOrgInvoice(p.orgInvoice)
                      ? {
                          amountCents: "all-remaining",
                          selfAccountId: getCurrentUserAccountId(),
                          note,
                          orgId: p.orgInvoice.orgId,
                          parentOrgInvoiceId: p.orgInvoice.id,
                          applicationMethod: OrgPaymentInvoiceCreditApplicationMethod.equal,
                          type: "all-outstanding"
                        }
                      : {
                          amountCents: "all-remaining",
                          selfAccountId: getCurrentUserAccountId(),
                          note,
                          orgId: p.orgInvoice.orgId,
                          parentOrgInvoiceId: p.orgInvoice.parentOrgInvoiceId,
                          orgInvoiceId: p.orgInvoice.id,
                          type: "single"
                        }
                  );

                  await p.onComplete();
                  p.onRequestDismiss();
                } catch (e) {
                  openErrorToast(
                    translate({
                      defaultMessage:
                        "There was a problem applying the credit. Please try again or contact support@olliesports.com"
                    })
                  );
                }
                setIsLoading(false);
              }
            }}
            onRequestDismiss={() => {
              if (
                note &&
                !window.confirm(translate({ defaultMessage: "You have unsaved changes. Are you sure you wish to leave?" }))
              ) {
                return;
              }

              p.onRequestDismiss();
            }}
          >
            <div>
              <View>
                <StyledText>
                  {translate(
                    { defaultMessage: "If you forgive this payment, a credit for {amount} will be generated." },
                    { amount: formatMoneyCentsToDollarCentPrettyString(p.remainingAmount) }
                  )}
                </StyledText>
              </View>

              <PrettyCoolTextInputWithLabel
                style={{ marginTop: 16 }}
                label={translate.common.Note}
                value={note}
                isRequired
                onChange={newVal => {
                  setNote(newVal ?? "");
                }}
              />
            </div>
          </FullScreenModal>
        );
      }}
    />
  );
}
