import Emittery from "emittery";

const CHANNEL = "change";

export class SingleChannelEventEmitter<T = never> {
  private ee: Emittery;
  constructor() {
    this.ee = new Emittery();
  }
  emit(payload: T) {
    return this.ee.emit(CHANNEL, payload);
  }
  onChange(fn: () => any): Unsubscribe {
    return this.ee.on(CHANNEL, fn);
  }
  onceChange() {
    return this.ee.once(CHANNEL);
  }
}

type Unsubscribe = () => void;
