import {
  AccountId,
  OrgRegistrationQuestion__AdditionalStep,
  OrgRegistrationSuccessMessage,
  OrgSeason,
  PrettyPlayerBundle
} from "@ollie-sports/models";
import { getServerHelpers } from "../helpers";
import { olliePipe__server__sendOlliePipeEvent } from "../api";
import { translate } from "@ollie-sports/i18n";
import { htmlify, linkify } from "./linkify";

export async function updateRegistrationAndInvoicesLastKeyActionMS(p: { accountIds: AccountId[] }) {
  const { appOllieFirestoreV2: h } = getServerHelpers();
  try {
    await Promise.all(
      p.accountIds.map(async accountId => {
        return h.AccountPrivate.update({
          id: accountId,
          doc: {
            settings: {
              registrationAndInvoicesLastKeyActionMS: Date.now()
            }
          }
        });
      })
    );
  } catch (e) {
    olliePipe__server__sendOlliePipeEvent({
      type: "error-updating-registration-and-invoices-key",
      payload: {
        accountIds: p.accountIds
      }
    });
  }
}

export function getRegistrationEmailBody(p: {
  successMessage?: OrgRegistrationSuccessMessage;
  locale: string;
  playerName: string;
  orgSeasonName: string;
  additionalSteps: OrgRegistrationQuestion__AdditionalStep[];
}) {
  return htmlify(`${
    p.successMessage?.message
      ? p.successMessage.message
      : translate({
          defaultMessage: "Thank you for registering the following player:",
          serverLocale: p.locale
        })
  }<br><br><strong>${translate.common(p.locale).Player}:</strong> ${p.playerName}<br><strong>${
    translate.common(p.locale).Season
  }:</strong> ${p.orgSeasonName}
  ${
    p.additionalSteps.length
      ? `<br><br>${translate({
          defaultMessage:
            "To finish registration, you must complete the following steps and then let a coach or team admin know so they can mark them off as complete.",
          serverLocale: p.locale
        })}<ul style="list-style-type: disc; margin-top: 10px; padding-left: 20px;">${p.additionalSteps
          .map((a, index) => {
            return `<li style="margin-top: 20px;"><div><strong >${a.shortTitle}</strong><div><br><div style="margin-bottom: 10px;">${a.descriptionMD}<div></li>`;
          })
          .join("")}</ul>`
      : ""
  }
  <br>${translate({
    defaultMessage: "You can view and manage your payment details on the Tools tab inside the Ollie app.",
    serverLocale: p.locale
  })}`);
}

// i18n certified - complete
