export function maskNumericText(input: string, mask: string) {
  let maskedOutput = "";
  let inputIndex = 0;

  for (let i = 0; i < mask.length; i++) {
    if (mask[i] === "8") {
      if (inputIndex < input.length) {
        maskedOutput += input[inputIndex];
        inputIndex++;
      } else {
        break;
      }
    } else {
      maskedOutput += mask[i];
    }
  }

  return maskedOutput.replace(/[^\d]$/, "");
}
