import Emittery from "emittery";

export function createSimpleWebWorker<T extends (args: any) => any>(fn: T): (a: Parameters<T>[0]) => Promise<ReturnType<T>> {
  const fnToExecuteName = "___FN_TO_EXECUTE___";
  const fnStrings = [
    `
  var ${fnToExecuteName} = ${fn.toString()};
  self.onmessage = function(e){
    const result = ${fnToExecuteName}(e.data.payload)
    self.postMessage({invocationId: e.data.invocationId, result: result})
  };
  `
  ];

  function setupWorker() {
    const thisWorker = new Worker(
      URL.createObjectURL(
        new Blob(fnStrings, {
          type: "text/javascript"
        })
      )
    );

    const ee = new Emittery();

    thisWorker.onmessage = function(event) {
      ee.emit(event.data.invocationId, {
        invocationId: event.data.invocationId,
        result: event.data.result
      });
    };

    return { worker: thisWorker, ee };
  }

  const context = { current: setupWorker() as null | { worker: Worker; ee: Emittery } };

  return async args => {
    if (!context.current) {
      context.current = await setupWorker();
    }

    const { worker, ee } = context.current;

    const invocationId = Math.random() + "" + Math.random();
    const resultProm = ee.once(invocationId) as Promise<{ invocationId: string; result: ReturnType<T> }>;
    worker.postMessage({
      invocationId,
      payload: args
    });

    return (await resultProm).result;
  };
}
