import { translate } from "@ollie-sports/i18n";
import { forceHttps } from "./url-helpers";

export function escapeHtmlAndWrapLinks(unsafeText: string): string {
  const allowedTags: string[] = ["a"];

  const tempDiv = document.createElement("div");
  tempDiv.textContent = unsafeText;

  const fragment = document.createDocumentFragment();
  fragment.appendChild(tempDiv);

  // Escape HTML except allowed tags
  Array.from(tempDiv.querySelectorAll("*")).forEach(el => {
    if (!allowedTags.includes(el.tagName.toLowerCase())) {
      const textNode = document.createTextNode(el.outerHTML);
      el.replaceWith(textNode);
    }
  });

  const urlOrEmailRegex =
    /(\bhttps?:\/\/(?:www\.)?[\w-]+(\.[\w-]+)*\.?(:\d+)?(\/\S*)?)|(\bwww\.[\w-]+(\.[\w-]+)*\.?(:\d+)?(\/\S*)?)|(\b[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+\b)/gi;

  // Replace function that distinguishes between URLs and emails
  const safeText = tempDiv.innerHTML.replace(urlOrEmailRegex, (match, p1, p2, p3, p4, p5, p6, p7, p8, p9) => {
    if (p9) {
      // This is an email
      return `<a class="text-blue-500 underline" href="mailto:${match}">${match}</a>`;
    } else {
      // This is a URL
      return `<a class="text-blue-500 underline" href="${forceHttps(match)}" target="_blank">${match}</a>`;
    }
  });

  return safeText;
}
