import { getUniversalHelpers } from "../helpers";
type Entry<T extends JsonValue> = {
  exp: number;
  v: T;
};

export async function setKeyValueStore<T extends JsonValue>(p: { key: string; value: T; expirationMS: number }) {
  await getUniversalHelpers().appOllieRtdb.keyValueStore(p.key).set({
    exp: p.expirationMS,
    v: p.value
  });
}

export function getKeyValueStore(key: string): Promise<JsonValue | undefined> {
  return getUniversalHelpers()
    .appOllieRtdb.keyValueStore(key)
    .once("value")
    .then(async a => {
      if (!a.exists()) {
        return undefined;
      }

      const { exp, v } = a.val() as Entry<any>;

      if (exp < Date.now()) {
        await getUniversalHelpers().appOllieRtdb.keyValueStore(key).remove();
        return undefined;
      }

      return v as JsonValue;
    });
}

export async function multiGetKeyValueStore(keys: string[]): Promise<Record<string, JsonValue | undefined>> {
  const vals = await Promise.all(keys.map(k => getKeyValueStore(k)));
  return keys.reduce((acc, a, i) => {
    acc[a] = vals[i];
    return acc;
  }, {} as Record<string, JsonValue | undefined>);
}

type JsonObject = { [Key in string]: JsonValue } & { [Key in string]?: JsonValue | undefined };
type JsonArray = JsonValue[] | readonly JsonValue[];
type JsonPrimitive = string | number | boolean | null;
type JsonValue = JsonPrimitive | JsonObject | JsonArray;
