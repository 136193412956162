import { MATCH_TYPES, AccountPrivate, AccountId, Account } from "@ollie-sports/models";
import _ from "lodash";
import moment from "moment";
import { getServerHelpers } from "../helpers";

export function getDefaultStartDateForTrends() {
  return moment().subtract(1, "year").startOf("day").valueOf();
}

export function getDefaultEndDateForTrends() {
  return moment().endOf("day").valueOf();
}

export function getDefaultGameTypesForTrends() {
  return [MATCH_TYPES.game, MATCH_TYPES.scrimmage];
}

const cacheValidationLengthInSeconds = 10;
const accountPrivateCache: Record<AccountId, { d: number; ap: AccountPrivate }> = {};
const accountCache: Record<AccountId, { d: number; acc: Account }> = {};
export async function fetchAccountPrivatesCached(p: { accountIds: string[] }): Promise<AccountPrivate[]> {
  // SERVER_ONLY_TOGGLE

  const { appOllieFirestoreV2: h } = getServerHelpers();
  const cutOff = Date.now() - cacheValidationLengthInSeconds * 1000;
  const accounts = await Promise.all(
    p.accountIds.map(async accId => {
      if (!accountPrivateCache[accId] || accountPrivateCache[accId].d < cutOff) {
        const accPrivate = await h.AccountPrivate.getDoc(accId);
        if (!accPrivate) {
          return null;
        }

        accountPrivateCache[accId] = { d: Date.now(), ap: accPrivate };
      }

      return accountPrivateCache[accId]?.ap;
    })
  );

  cleanAccountPrivateCache();

  return _.compact(accounts);
  // SERVER_ONLY_TOGGLE
}

const cleanAccountPrivateCache = _.throttle(() => {
  const cutOff = Date.now() - cacheValidationLengthInSeconds * 1000;

  const ids = Object.keys(accountPrivateCache);
  for (let i = 0; i < ids.length; i++) {
    const id = ids[i];
    if (accountPrivateCache[id].d < cutOff) {
      delete accountPrivateCache[id];
    }
  }
}, 20 * 1000);

export async function fetchAccountsCached(p: { accountIds: string[] }): Promise<Account[]> {
  // SERVER_ONLY_TOGGLE

  const { appOllieFirestoreV2: h } = getServerHelpers();
  const cutOff = Date.now() - cacheValidationLengthInSeconds * 1000;
  const accounts = await Promise.all(
    p.accountIds.map(async accId => {
      if (!accountCache[accId] || accountCache[accId].d < cutOff) {
        const account = await h.Account.getDoc(accId);
        if (!account) {
          return null;
        }

        accountCache[accId] = { d: Date.now(), acc: account };
      }

      return accountCache[accId]?.acc;
    })
  );

  cleanAccountCache();

  return _.compact(accounts);
  // SERVER_ONLY_TOGGLE
}

const cleanAccountCache = _.throttle(() => {
  const cutOff = Date.now() - cacheValidationLengthInSeconds * 1000;

  const ids = Object.keys(accountCache);
  for (let i = 0; i < ids.length; i++) {
    const id = ids[i];
    if (accountCache[id].d < cutOff) {
      delete accountCache[id];
    }
  }
}, 20 * 1000);

// i18n certified - complete
