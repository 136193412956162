type UnionKeys<T> = T extends any ? keyof T : never;

export function ObjectKeys<T extends {}>(obj: T) {
  return Object.keys(obj) as UnionKeys<T>[];
}

export function ObjectValues<T extends Record<PropertyKey, PropertyKey>>(obj: T): (keyof InvertResult<T>)[] {
  return Object.values(obj) as (keyof InvertResult<T>)[];
}

export type AllValues<T extends object> = {
  [P in keyof T]: { key: P; value: T[P] };
}[keyof T];

export type InvertResult<T extends Record<PropertyKey, PropertyKey>> = {
  [P in AllValues<T>["value"]]: Extract<AllValues<T>, { value: P }>["key"];
};

export type DistributiveOmit<T, K extends keyof T> = T extends unknown ? Omit<T, K> : never;
export type DistributiveExtend<T extends object, Props extends object> = T extends any ? T & Props : never;
export type DistributivePick<T, K extends keyof T> = T extends unknown ? Pick<T, K> : never;
