import { FeatureToggles } from "@ollie-sports/models";
import { getUniversalHelpers } from "../helpers";

export async function fetchFeatureToggle(feature: FeatureToggles) {
  const { appOllieRtdb } = getUniversalHelpers();

  const ref = appOllieRtdb._RawRtdb.ref(`systemStatus/globalFeatureToggles/${feature}`);
  const snap = await ref.once("value");

  return !!snap.val() as boolean;
}

// i18n certified - complete
