import { useReducer, useEffect } from "react";
import Emittery from "emittery";
import _ from "lodash";
import zustand from "zustand";

export function createSimpleStore<StoreState>(initialState: StoreState, opts?: { renderDebounceMS?: number }) {
  const ee = new Emittery();
  const emitChange = opts?.renderDebounceMS
    ? _.debounce(
        () => {
          ee.emit("change");
        },
        opts.renderDebounceMS,
        { leading: false, trailing: true }
      )
    : () => ee.emit("change");

  let currState = initialState;
  return {
    set(newVal: StoreState) {
      currState = newVal;
      emitChange();
    },
    get() {
      return currState;
    },
    useStore(): StoreState {
      const [__, forceRender] = useReducer(a => !a, false);
      useEffect(() => {
        console.log("Subscribing");
        return ee.onAny(() => {
          forceRender();
        });
      }, []);
      return currState;
    }
  };
}
