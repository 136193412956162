import { MarketingEvent } from "@ollie-sports/models";
import { OlliePipe } from "@ollie-sports/pipe";

export function emitMarketingEvent(p: { olliePipe: OlliePipe; event: MarketingEvent }) {
  return p.olliePipe.emitEvent(
    {
      type: "marketing-" + p.event.type,
      payload: p.event
    },
    { sendImmediate: true } //Some events depend on earlier events, so try to emit as early as possible
  );
}

// i18n certified - complete
