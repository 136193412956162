import { translate } from "@ollie-sports/i18n";
import _ from "lodash";
import {
  CalendarEntryGameScrimmage,
  CALENDAR_ENTRY_TYPES,
  COLORED_JERSEY_PLAYERS_COLORS,
  GAME_VENUES,
  GENDERS,
  mapPlayerInfoArray,
  PrettyPlayer,
  sandboxCalendarEntryId,
  sandboxColoredJerseyTeamId,
  sandboxTeamId,
  SANDBOX_PLAYERS_INFO,
  SANDBOX_PLAYER_IDS,
  Team,
  TEAM_SPORT,
  TEAM_TYPES
} from "@ollie-sports/models";
import moment from "moment";

export function GET_SANDBOX_TEAM(p: { locale: string; overridePrettyPlayers?: PrettyPlayer[] }): Team {
  return {
    id: sandboxTeamId,
    name: translate({ defaultMessage: "Practice Session Team", serverLocale: p.locale }),
    shortName: translate({ defaultMessage: "Demo T", description: "Short for Demo Team", serverLocale: p.locale }),
    birthYear: "2005",
    gender: GENDERS.boys,
    country: "US",
    createdAtMS: 1564611142357,
    deletedAtMS: 0,
    teamType: TEAM_TYPES.sandbox,
    province: "UT",
    regionCode: "84042",
    timezone: "America/Denver",
    derived: {
      activePlayerBundleIds: {},
      activePlayerIds: (p.overridePrettyPlayers ?? SANDBOX_PLAYERS_INFO).reduce((acc, val) => {
        acc[val.player.id] = true;
        return acc;
      }, {} as Record<string, true>)
    },
    teamSport: TEAM_SPORT.soccer,
    accounts: {}
  };
}

export function GET_SANDBOX_CAL_ENTRY(locale: string): CalendarEntryGameScrimmage {
  return {
    id: sandboxCalendarEntryId,
    calendarEntryType: CALENDAR_ENTRY_TYPES.game,
    location: {},
    startDateTime: moment().format("YYYY-MM-DD[T]HH:mm"),
    endDateTime: moment().add(90, "minutes").format("YYYY-MM-DD[T]HH:mm"),
    timezone: "America/Denver",
    opponentName: translate({ defaultMessage: "Practice Session Opponent", serverLocale: locale }),
    gameVenue: GAME_VENUES.home,
    teamIdWithSquad: `${sandboxCalendarEntryId}-none`,
    teamId: sandboxTeamId,
    createdAtMS: Date.now()
  };
}

export const GET_COLORED_JERSEY_PLAYERS = _.memoize((locale: string): PrettyPlayer[] => {
  return [
    [SANDBOX_PLAYER_IDS.n1, translate({ defaultMessage: "Red", serverLocale: locale }), "", COLORED_JERSEY_PLAYERS_COLORS.red],
    [SANDBOX_PLAYER_IDS.n2, translate({ defaultMessage: "Pink", serverLocale: locale }), "", COLORED_JERSEY_PLAYERS_COLORS.pink],
    [
      SANDBOX_PLAYER_IDS.n3,
      translate({ defaultMessage: "Purple", serverLocale: locale }),
      "",
      COLORED_JERSEY_PLAYERS_COLORS.purple
    ],
    [
      SANDBOX_PLAYER_IDS.n4,
      translate({ defaultMessage: "Green", serverLocale: locale }),
      "",
      COLORED_JERSEY_PLAYERS_COLORS.green
    ],
    [SANDBOX_PLAYER_IDS.n5, translate({ defaultMessage: "Blue", serverLocale: locale }), "", COLORED_JERSEY_PLAYERS_COLORS.blue],
    [
      SANDBOX_PLAYER_IDS.n6,
      translate({ defaultMessage: "White", serverLocale: locale }),
      "",
      COLORED_JERSEY_PLAYERS_COLORS.white
    ],
    [
      SANDBOX_PLAYER_IDS.n7,
      translate({ defaultMessage: "Yellow", serverLocale: locale }),
      "",
      COLORED_JERSEY_PLAYERS_COLORS.yellow
    ],
    [
      SANDBOX_PLAYER_IDS.n8,
      translate({ defaultMessage: "Orange", serverLocale: locale }),
      "",
      COLORED_JERSEY_PLAYERS_COLORS.orange
    ]
  ].map(mapPlayerInfoArray.bind(null, sandboxColoredJerseyTeamId));
});
