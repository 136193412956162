import { useLayoutEffect, useMemo, useReducer, useRef } from "react";
import { createPersistedSynchronousStore } from "./createPersistedSynchronousStore";
import { createZustandStore } from "./createZustandStore";
import { dequal } from "dequal";
function getCacheKey(key: string) {
  return "USE_PERSISTENT_STATE-" + key;
}

const persistedStateStore = createPersistedSynchronousStore<Record<string, any>>({
  initialState: {},
  namespace: "persistent-state",
  shouldBeClearedOnUpdates: true,
  timeToLiveMS: 1000 * 60 * 60 * 15
});

const sessionPersistedStateStore = createZustandStore<Record<string, any>>({});

export function usePersistentState<T>(opts: {
  key: string;
  initialValue: T;
  beforeLoad?: (curr: T) => T;
}): [T, (newVal: T) => void | ((setter: (currVal: T) => T) => void), { revertToInitialValue: () => void; hasLoaded: boolean }] {
  const cacheKey = getCacheKey(opts.key);
  const hasInitializedByCacheKey = useRef<Record<string, true>>({});

  useLayoutEffect(() => {
    persistedStateStore.hasInitialized.then(() => {
      const currVal = persistedStateStore.getStore()[cacheKey];

      if (opts.beforeLoad && !hasInitializedByCacheKey.current[cacheKey]) {
        //Trigger beforeLoad as a gross side effect...
        const newVal = opts.beforeLoad(currVal);
        if (!dequal(newVal, currVal)) {
          persistedStateStore.setStore({ [cacheKey]: newVal });
        }
      }

      hasInitializedByCacheKey.current[cacheKey] = true;
    });
  }, [cacheKey]);

  const val = persistedStateStore.useStore(a => {
    if (!persistedStateStore.hasInitialized.isResolved) {
      return opts.initialValue;
    }

    return a[cacheKey];
  });

  return [
    typeof val === "undefined" ? opts.initialValue : val,
    setterOrVal => {
      if (typeof setterOrVal === "function") {
        persistedStateStore.setStore({ [cacheKey]: setterOrVal(persistedStateStore.getStore()[cacheKey] ?? opts.initialValue) });
      } else {
        persistedStateStore.setStore({ [cacheKey]: setterOrVal });
      }
    },
    {
      revertToInitialValue() {
        persistedStateStore.setStore({ [cacheKey]: opts.initialValue });
      },
      hasLoaded: !!hasInitializedByCacheKey.current[cacheKey]
    }
  ];
}

export function useSessionPersistentState<T>(opts: {
  key: string;
  initialValue: T;
}): [T, (newVal: T) => void | ((setter: (currVal: T) => T) => void)] {
  const cacheKey = "SESSION_" + getCacheKey(opts.key);

  if (!sessionPersistedStateStore.get()[cacheKey]) {
    sessionPersistedStateStore.modifyImmutably(a => {
      if (sessionStorage[cacheKey]) {
        try {
          a[cacheKey] = JSON.parse(sessionStorage[cacheKey]);
        } catch (e) {
          console.error("Bad value written to sesssion storage! Reverting to default", e);
        }
      }

      if (!a[cacheKey]) {
        a[cacheKey] = opts.initialValue;
      }
    });
  }

  const val = sessionPersistedStateStore.useStore(a => a[cacheKey]);

  return [
    val,
    setterOrVal => {
      sessionPersistedStateStore.modifyImmutably(a => {
        if (typeof setterOrVal === "function") {
          a[cacheKey] = setterOrVal(a[cacheKey]);
        } else {
          a[cacheKey] = setterOrVal;
        }

        try {
          sessionStorage[cacheKey] = JSON.stringify(a[cacheKey]);
        } catch (e) {
          console.error("Trying to write bad value to session storage! Not saving!", e);
        }
      });
    }
  ];
}

// i18n certified - complete
