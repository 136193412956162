import { OrgFeeDetails, PaymentMethodType } from "@ollie-sports/models";
import { Coupon, CouponType, OrgCoupon, OrgCouponType } from "@ollie-sports/models";
import { PricingInfo, StripePricingInfo } from "../constants/DerivedModels";

export function calculateFeesForPayment(p: {
  chargeAmountCents: number;
  feeDetails?: OrgFeeDetails;
  paymentMethodType: PaymentMethodType;
}) {
  if (p.chargeAmountCents === 0) {
    return 0;
  }
  const feeDetails = p.feeDetails
    ? { percent: p.feeDetails.percent, flatAmountCents: p.feeDetails.flatAmountCents }
    : {
        percent: p.paymentMethodType === PaymentMethodType.card ? 0.04 : 0.02,
        flatAmountCents: p.paymentMethodType === PaymentMethodType.card ? 90 : 500
      };
  return Math.ceil(
    roundNumberToTwoDecimalPlaces(
      (p.chargeAmountCents + feeDetails.flatAmountCents) / (1 - feeDetails.percent) - p.chargeAmountCents
    )
  );
}

export function roundNumberToTwoDecimalPlaces(num: number) {
  return parseFloat(num.toFixed(2));
}

export function formatMoneyValue(value: number) {
  const totalDollars = Math.floor(value / 100);
  const thousandDollars = Math.floor(totalDollars / 1000);
  const dollars = totalDollars % 1000;
  return `${thousandDollars ? `${thousandDollars},` : ""}${dollars}.${value % 100 < 10 ? `0${value % 100}` : value % 100}`;
}

export function getDiscountedAmount(p: { appliedCoupon?: OrgCoupon; pricingInfo: PricingInfo }) {
  if (!p.appliedCoupon) {
    return 0;
  }
  switch (p.appliedCoupon.type) {
    case OrgCouponType.amountOff:
      return Math.min(p.pricingInfo.numberCents, p.appliedCoupon.amountCents);
    case OrgCouponType.percentOff:
      if (p.appliedCoupon.percent > 0 && p.appliedCoupon.percent <= 1) {
        return p.appliedCoupon.percent * p.pricingInfo.numberCents;
      } else {
        return 0;
      }
  }
}

export function getCostAfterDiscount(p: { appliedCoupon?: OrgCoupon; pricingInfo: PricingInfo }) {
  return p.pricingInfo.numberCents - getDiscountedAmount(p);
}

export function getTodayPaymentDetails(p: { pricingInfo: PricingInfo; appliedCoupon?: OrgCoupon }) {
  const discountAmountCents = getDiscountedAmount(p);
  const costAfterDiscount = getCostAfterDiscount(p);
  const otherFeesAmountDueCents = calculateFeesForPayment({
    chargeAmountCents: costAfterDiscount,
    feeDetails: p.pricingInfo.feeDetails,
    paymentMethodType: PaymentMethodType.card
  });

  return {
    subtotalAmountCents: p.pricingInfo.numberCents,
    discountAmountCents,
    baseAmountDueCents: costAfterDiscount,
    otherFeesAmountDueCents,
    totalAmountDueCents: costAfterDiscount + otherFeesAmountDueCents,
    appliedOrgCouponDetails:
      p.appliedCoupon && discountAmountCents
        ? {
            orgCouponId: p.appliedCoupon.id,
            orgCouponCode: p.appliedCoupon.code,
            discountAmountCents: discountAmountCents
          }
        : undefined
  };
}
