import { getServerHelpers } from "../helpers";
import type { Twilio } from "twilio";
import { isProduction } from "./env-helpers";

let client: Twilio;

const whitelistedDevNumbers = [
  "+18016570365", //Nate Gygi...
  "+18018153899", //Scott Ashton...
  "+18018642609", //Creed Naylor...
  "+13853526936", // Cort,
  "+13852399714", // Sol
  "+14357035517", // Kyle
  "+13853297800" // Mark
];

export async function sendTwilioSMS(
  p: { to: string; body: string },
  retryCount = 0
): Promise<{ status: "failure"; errorCode: number } | { status: "success" }> {
  // SERVER_ONLY_TOGGLE
  const { serverConfig } = getServerHelpers();

  if (!client) {
    //TODO: The auth id should be a config variable...
    client = require("twilio")("AC21b5dfe60abe39d19017272418a72a7a", serverConfig.twilioAuthToken) as Twilio;
  }

  const normTo = normalizePhoneNumber(p.to);

  //Only send texts to whitelist numbers in dev...
  if (!isProduction() && !whitelistedDevNumbers.some(a => a === normTo)) {
    new Promise(res => setTimeout(res, 500));
    return { status: "success" };
  }

  const inst = await client.messages.create({
    body: p.body,
    //TODO: This "from" number should be a config value. And we should rotate through a pool of numbers, not just hard code one.
    from: "+18446251171",
    to: normTo
  });

  if (inst.status === "failed" || inst.status === "canceled" || inst.status === "undelivered") {
    if (inst.errorCode === 10004 && retryCount < 3) {
      await new Promise(res => setTimeout(res, Math.random() * 5000));
      return await sendTwilioSMS(p, ++retryCount);
    } else {
      return {
        status: "failure",
        errorCode: inst.errorCode
      };
    }
  }

  return {
    status: "success"
  };
  // SERVER_ONLY_TOGGLE
}

function normalizePhoneNumber(phoneNumber: string): string {
  const numericPhoneNumber = phoneNumber.replace(/\D+/g, "");
  if (phoneNumber[0] === "+") {
    //If the phone number starts with a +, assume the phone number is mostly good
    return "+" + numericPhoneNumber;
  } else if (numericPhoneNumber.length === 10) {
    return "+1" + numericPhoneNumber;
  } else {
    return "+" + numericPhoneNumber;
  }
}
