import deepDiff from "deep-diff";
import _ from "lodash";
import { MagicDeleteString } from "../constants/FirestoreLiftConstants";

export function extractFirestoreLiftObjectDiff<T extends Object>(initialObj: T, newObj: T) {
  const diff = deepDiff.diff(initialObj, newObj);

  if (!diff) {
    return undefined;
  }

  const partial: Partial<T> = {};
  diff.forEach(change => {
    if (change.kind === "D") {
      change.path;
      _.set(partial, change.path!, MagicDeleteString);
    } else {
      deepDiff.applyChange(partial, null, change);
    }
  });

  return partial;
}

// i18n certified - complete
