import { useEffect } from "react";
import { CenteredLoader } from "../components/CenteredLoader";
import { openModal } from "../components/modals/imperativeModal";

export function openLoadingIndicator() {
  return openModal({
    body: (
      <div className="absolute flex z-50 inset-0 justify-center items-center pointer-events-none">
        <div className="p-8 bg-white shadow-xl rounded-full pointer-events-auto">
          <CenteredLoader />
        </div>
      </div>
    )
  });
}

export function LoadingIndicator(p: { isVisible: boolean; minDelay?: number }) {
  useEffect(() => {
    if (p.isVisible) {
      let close = () => {};

      const timeout = setTimeout(() => {
        const loader = openLoadingIndicator();
        close = loader.close;
      }, p.minDelay || 0);

      return () => {
        clearTimeout(timeout);
        close();
      };
    }
    return;
  }, [p.isVisible, p.minDelay]);

  return null;
}
