import { dateFormatters } from "@ollie-sports/i18n";
import { OrgRegistrationAnswer, OrgRegistrationQuestionType } from "@ollie-sports/models";
import moment from "moment";

export function getOrgRegistrationAnswerStringOrArray(p: {
  orgRegistrationAnswer: OrgRegistrationAnswer;
  locale: string;
}): string | string[] {
  switch (p.orgRegistrationAnswer.type) {
    case OrgRegistrationQuestionType.checkbox:
      return p.orgRegistrationAnswer.selections;
    case OrgRegistrationQuestionType.legalDoc:
      return dateFormatters.mm_dd_yyyy(moment(p.orgRegistrationAnswer.agreedToAtMS).toDate(), p.locale);
    case OrgRegistrationQuestionType.radio:
      return p.orgRegistrationAnswer.selection;
    case OrgRegistrationQuestionType.freeResponse:
      return p.orgRegistrationAnswer.response;
    case OrgRegistrationQuestionType.additionalStep:
      return dateFormatters.mm_dd_yyyy(moment(p.orgRegistrationAnswer.completedAtMS).toDate(), p.locale);
  }
}
