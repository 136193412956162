import { translate } from "@ollie-sports/i18n";
import _ from "lodash";
import CoolerTable from "../components/CoolerTable";
import { CoolCheckboxInput } from "../components/Inputs/CoolCheckboxInput";
import { CoolTextInput } from "../components/Inputs/CoolTextInput";
import { registrationQuestionTypeLabels } from "../constants";
import { cleanlyCutString } from "./cleanlyCutString";
import { OrgRegistrationQuestion } from "@ollie-sports/models";
import { openFadingModal, openModal } from "../components/modals/imperativeModal";
import { useState } from "react";
import { useImmutableState } from "./useImmutableState";
import { FullScreenModal } from "../components/modals/getFullscreenModal";
import { getOrgRegistrationQuestionMainBodyText } from "@ollie-sports/core";

type Props = {
  //Only pass in questions that have not yet been selected by the user.
  unselectedOrgQuestions: OrgRegistrationQuestion[];
};

export function openSelectOrgQuestions(p: Props) {
  return new Promise<null | string[]>(res => {
    const modal = openModal({
      body: (
        <SelectOrgQuestions
          {...p}
          onRequestDismiss={() => {
            modal.close();
            res(null);
          }}
          onComplete={selected => {
            modal.close();
            res(selected);
          }}
        />
      )
    });
  });
}

function SelectOrgQuestions(p: Props & { onRequestDismiss: () => void; onComplete: (selectedIds: string[]) => void }) {
  const [selectedQuestions, modifySelectedQuestions] = useImmutableState<string[]>([]);
  const [searchInput, setSearchInput] = useState("");
  return (
    <FullScreenModal
      backButtonText={translate.common.Back}
      title={translate({ defaultMessage: "Select Questions To Add" })}
      bottomButton={{
        enabled: !!selectedQuestions.length,
        title: translate({ defaultMessage: "Add {num} Questions" }, { num: selectedQuestions.length }),
        onPress: async () => {
          p.onComplete(selectedQuestions);
        }
      }}
      onRequestDismiss={p.onRequestDismiss}
    >
      <CoolerTable
        getItemKey={a => a.id}
        items={p.unselectedOrgQuestions || []}
        defaultSortSettings={{ dir: "asc", label: translate.common.Title }}
        style={{ height: "100vh", flex: 1 }}
        onRowClick={item => {
          modifySelectedQuestions(s => {
            if (s.includes(item.id)) {
              s.splice(s.indexOf(item.id), 1);
            } else {
              s.push(item.id);
            }
          });
        }}
        columnDefs={[
          {
            label: translate.common.Title,
            getValue: item => item.shortTitle,
            sortItems: (items, dir) => {
              return _.orderBy(items, a => a.shortTitle.toLowerCase(), dir);
            }
          },

          {
            getValue: item => cleanlyCutString(getOrgRegistrationQuestionMainBodyText(item), 250),
            label: translate({ defaultMessage: "Question/Text" }),
            sortItems: (items, dir) => {
              return _.orderBy(items, item => getOrgRegistrationQuestionMainBodyText(item).toLowerCase(), dir);
            }
          },

          {
            label: translate({ defaultMessage: "Type" }),
            getValue: item => registrationQuestionTypeLabels[item.type],
            sortItems: (items, dir) => {
              return _.orderBy(items, item => registrationQuestionTypeLabels[item.type].toLowerCase(), dir);
            }
          },
          {
            getValue: item => (
              <div className="pointer-events-none">
                <CoolCheckboxInput
                  label=""
                  labelType="inside"
                  onChange={() => {}}
                  value={!!selectedQuestions.includes(item.id)}
                />
              </div>
            ),
            label: translate({ defaultMessage: "Selected" })
          }
        ]}
        filters={[
          {
            filterComponent: (
              <CoolTextInput
                value={searchInput}
                onChange={newVal => {
                  setSearchInput(newVal ?? "");
                }}
                showClearButton
                inputProps={{
                  autoFocus: true,
                  placeholder: translate.common.Search + "..."
                }}
              />
            ),
            onFilter: items => {
              const terms = searchInput.toLowerCase().split(/ +/);
              return items.filter(item => {
                const body = cleanlyCutString(getOrgRegistrationQuestionMainBodyText(item), 250);
                const haystack = [item.shortTitle, body].join(" ").toLowerCase();
                return terms.every(term => haystack.includes(term));
              });
            }
          }
        ]}
      />
    </FullScreenModal>
  );
}
