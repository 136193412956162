import { translate } from "@ollie-sports/i18n";

export function combineArrayWithCommasAndAnd(array: any[], locale: string) {
  return array.length === 1
    ? `${array[0]}`
    : array.length === 2
    ? `${array.join(` ${translate.common(locale).And.toLowerCase()} `)}`
    : `${array.slice(0, array.length - 1).join(", ")}, ${translate.common(locale).And.toLowerCase()} ${array[array.length - 1]}`;
}

// i18n certified - complete
