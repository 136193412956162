import zustand, { UseStore, State, SetState, GetState, Subscribe } from "zustand";
import { createAsyncStorageWrapper } from "./createAsyncStorageWrapper";
import { Deferred, deferred } from "./deferred";
import PromiseQueue from "promise-queue";

export type PersistedSynchronousStore<T extends State> = {
  useStore: UseStore<T>;
  getStore: GetState<T>;
  setStore: SetState<T>;
  subscribe: Subscribe<T>;
  hasInitialized: Deferred<void>;
};

const stores: Record<string, PersistedSynchronousStore<any>> = {};

let storesHaveClearedIfNeedBe: Promise<any> = Promise.resolve();

const GIT_HASH_KEY = "persisted-store-saved-git-hash";
const SHOULD_STORE_BE_CLEARED_ON_UPDATE_KEY = "stores-to-maybe-be-cleared-on-update";
const prevGitHash = localStorage.getItem(GIT_HASH_KEY);
localStorage.setItem(GIT_HASH_KEY, process.env.INJECTED_GIT_HASH || "");
if (prevGitHash !== process.env.INJECTED_GIT_HASH) {
  const toUpdateStores = getStoresThatShouldBeClearedOnUpate();
  storesHaveClearedIfNeedBe = Promise.all(
    Object.keys(toUpdateStores).map(async storeNamespace => {
      if (toUpdateStores[storeNamespace]) {
        await createAsyncStorageWrapper({ namespace: storeNamespace }).clear();
      }
    })
  );
}

//Creates a disk persisted store that can be accessed synchronously. Has a small delay on initialization though
export function createPersistedSynchronousStore<State extends Record<any, any>>(p: {
  initialState: State;
  namespace: string;
  shouldBeClearedOnUpdates: boolean;
  timeToLiveMS?: number;
}): PersistedSynchronousStore<State> {
  if (!stores[p.namespace]) {
    const hasInitialized = deferred<void>();
    const useStore = zustand<State>(() => p.initialState);
    const { getState, setState, subscribe } = useStore;

    const promiseQueue = new PromiseQueue(1, Infinity);

    const KEY = "value";
    const AsyncStorage = createAsyncStorageWrapper<State>({
      namespace: p.namespace
    });

    storesHaveClearedIfNeedBe.then(async () => {
      setIfStoreShouldBeClearedOnUpdate(p.namespace, p.shouldBeClearedOnUpdates);
      const savedVal = await AsyncStorage.getItem(KEY);
      hasInitialized.resolve();
      if (savedVal) {
        setState(savedVal);
      }
    });

    subscribe(newStore => {
      promiseQueue.add(() => AsyncStorage.setItem(KEY, newStore));
    });

    const wrappedSubscribe = (fn: any) => {
      let hasUnsubbed = false;
      const unsub = subscribe(() => {
        hasInitialized.then(() => {
          if (hasUnsubbed) {
            return;
          }

          fn(getState());
        });
      });

      return () => {
        hasUnsubbed = true;
        unsub();
      };
    };

    const store: PersistedSynchronousStore<State> = {
      useStore,
      subscribe: wrappedSubscribe,
      getStore: getState,
      setStore: setState,
      hasInitialized
    };

    stores[p.namespace] = store;
  }

  return stores[p.namespace];
}

function setIfStoreShouldBeClearedOnUpdate(namespace: string, val: boolean) {
  try {
    const curr = JSON.parse(localStorage.getItem(SHOULD_STORE_BE_CLEARED_ON_UPDATE_KEY) || "{}");
    localStorage.setItem(SHOULD_STORE_BE_CLEARED_ON_UPDATE_KEY, JSON.stringify({ ...curr, [namespace]: val }));
  } catch (e) {
    console.error("Problem setting if store should be cleared on update!", namespace, e);
  }
}

function getStoresThatShouldBeClearedOnUpate(): Record<string, boolean> {
  try {
    return JSON.parse(localStorage.getItem(SHOULD_STORE_BE_CLEARED_ON_UPDATE_KEY) || "{}");
  } catch (e) {
    console.error("Problem getting stores that should be cleared on update", e);
    return {};
  }
}
